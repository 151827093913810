import React from "react";
import { StyledTournamentTabsHeading } from "../../styles/StyledComponents";
import { Typography } from '@mui/material';

const TournamentTabs = ({ label, active, onClick, ...props }) => {
  return (
    <div>
      <StyledTournamentTabsHeading 
        active={active ? 1 : 0}
        onClick={onClick}
        {...props}
      >
        {label}
      </StyledTournamentTabsHeading>
    </div>
  );
};

export default TournamentTabs;
