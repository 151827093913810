import React, { useState, useEffect } from 'react';
import { Box, Divider, Grid, Button, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SideNavbar from '../../components/Navbar/SideNavbar';
import TopPlayers from '../../components/Dashboard/TopPlayers';
import WelcomeHeading from '../../components/Dashboard/WelcomeHeading';
import {
    AddSubPlayerContainer,
    AddSubPlayerHeader,
    MatchesContainer,
    StyledMainCardContainer,
    StyledMainContainer,
    StyledMatchCard,
    StyledMatchCardTitle,
    StyledScheduleDates,
    StyledScoreCard,
    StyledSpaceBetween,
    StyledTitle,
    StyledTotalScore
} from '../../styles/StyledComponents';
import SwipeableDrawerComponent from '../../components/Drawer/SwipeableDrawerComponent';
import axiosInstance from "../../utils/axios";
import { useToast } from "../../utils/ToastContext";

const ReplaceSubPlayer = () => {
    const { id, tournamentId } = useParams();
    const [tournamentData, setTournamentData] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerComponent, setDrawerComponent] = useState('');
    const [selectedMatchId, setSelectedMatchId] = useState(null);
    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    const [selectedMatchName, setSelectedMatchName] = useState('');

    const showToast = useToast();

    useEffect(() => {
        const fetchTournamentData = async () => {
            try {
                const response = await axiosInstance.get(`/tournament/${tournamentId}`);
                setTournamentData(response.data.body);
            } catch (error) {
                console.error("Error fetching tournament data:", error);
            }
        };

        fetchTournamentData();
    }, [tournamentId]);

    const handleOpenDrawer = (matchId, matchName, playerId) => {
        setSelectedMatchId(matchId);
        setSelectedPlayerId(playerId);
        setSelectedMatchName(matchName);
        setDrawerComponent('addSubPlayers');
        setIsDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
        setDrawerComponent('');
    };

    const handleNext = async (selectedSubPlayerId) => {
        const newReplacementData = {
            matchId: selectedMatchId,
            tournamentId,
            playerId: selectedPlayerId,
            players: [selectedSubPlayerId],
        };

        try {
            const response = await axiosInstance.put('/mapSubPlayer', newReplacementData);
            if (response.status === 200) {
                showToast("Mapped the player successfully!", "success");
            } else {
                showToast("Failed to map the player", "error");
            }
        } catch (error) {
            showToast(`Error: ${error.message}`, "error");
        }

        handleCloseDrawer();
    };

    if (!tournamentData) return null;

    const playerMatches = tournamentData.matches
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
        .map(match => {
            const playerInfo = match.playerDetails.find(player => player.id === id);
            return {
                matchId: match._id,
                name: match.name,
                startDate: match.startDate,
                availability: playerInfo.availability,
                score: playerInfo.score,
            };
        });

    const totalScore = playerMatches.reduce((acc, match) => acc + (match.score || 0), 0);
    const averageScore = (totalScore / playerMatches.length).toFixed(2);

    return (
        <StyledMainContainer>
            <Box sx={{ display: 'flex' }}>
                <SideNavbar />
                <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
                    <Grid item xs={12}>
                        <StyledSpaceBetween>
                            <WelcomeHeading label={tournamentData.name} variant="h6" />
                        </StyledSpaceBetween>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <StyledMainCardContainer sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                            <Divider />
                            <Box sx={{ marginTop: '20px', flexGrow: 1 }}>
                                <AddSubPlayerContainer>
                                    <AddSubPlayerHeader sx={{ display: 'flex' }}>
                                        <Box sx={{ display: 'flex' }}> <img src='/images/svg/tournament-icon.svg' alt='' style={{ marginRight: '10px' }} />
                                            <StyledTitle variant="body1" sx={{ marginTop: '3px' }}>
                                                {tournamentData.name}
                                            </StyledTitle></Box>
                                        <StyledScheduleDates variant="body1">
                                            Schedule Dates:&nbsp;<b>{new Date(
                                                tournamentData.startTime.replace("26", "06")
                                            ).toLocaleDateString("en-US", {
                                                day: "2-digit",
                                                month: "short",
                                                year: "numeric",
                                            })} - {new Date(
                                                tournamentData.endTime.replace("26", "06")
                                            ).toLocaleDateString("en-US", {
                                                day: "2-digit",
                                                month: "short",
                                                year: "numeric",
                                            })}</b>
                                        </StyledScheduleDates>
                                    </AddSubPlayerHeader>
                                    <MatchesContainer>
                                        {playerMatches.map((match, index) => (
                                            <Tooltip key={index} title={new Date(match.startDate).toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" })}>
                                                <StyledMatchCard>
                                                    <StyledMatchCardTitle variant="body2">{match.name}</StyledMatchCardTitle>
                                                    {match.availability ? (
                                                        <CheckCircleIcon color="success" />
                                                    ) : (
                                                        <CancelIcon color="error" />
                                                    )}
                                                    {match.availability ? (
                                                        <StyledMatchCardTitle variant="body2">{match.score}</StyledMatchCardTitle>
                                                    ) : (
                                                        <Button variant="text" sx={{ margin: '0px', padding: '0px', fontSize: '10px' }} onClick={() => handleOpenDrawer(match.matchId, match.name, id)}>Add Sub</Button>
                                                    )}
                                                </StyledMatchCard>
                                            </Tooltip>
                                        ))}
                                        <StyledScoreCard >
                                            <StyledMatchCardTitle variant="body2">Total</StyledMatchCardTitle>
                                            <StyledTotalScore variant="h5">{totalScore}</StyledTotalScore>
                                        </StyledScoreCard>
                                        <StyledScoreCard bgColor="#E6F1E6" >
                                            <StyledMatchCardTitle variant="body2">Average</StyledMatchCardTitle>
                                            <StyledTotalScore variant="h5" sx={{ color: '#0F8B14' }}>{averageScore}</StyledTotalScore>
                                        </StyledScoreCard>
                                    </MatchesContainer>
                                </AddSubPlayerContainer>
                            </Box>
                        </StyledMainCardContainer>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TopPlayers />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <SwipeableDrawerComponent
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
                component={drawerComponent}
                matchId={selectedMatchId}
                playerId={selectedPlayerId}
                matchName={selectedMatchName}
                onSubmit={handleNext}
            />
        </StyledMainContainer>
    );
};

export default ReplaceSubPlayer;
