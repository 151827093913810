import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const RulesComponent = ({ onRulesChange }) => {
  const [rulesList, setRulesList] = useState([]);
  const [ruleType, setRuleType] = useState("");
  const [ruleDescription, setRuleDescription] = useState("");

  const handleAddRule = () => {
    if (ruleType && ruleDescription) {
      const newRule = { type: ruleType, description: ruleDescription };
      const updatedRules = [newRule, ...rulesList]; // Add new rule at the top
      setRulesList(updatedRules);
      setRuleType("");
      setRuleDescription("");
      onRulesChange(updatedRules);
    }
  };

  const handleDeleteRule = (index) => {
    const updatedRules = rulesList.filter((_, i) => i !== index);
    setRulesList(updatedRules);
    onRulesChange(updatedRules);
  };

  return (
    <Box sx={{ margin: "24px" }}>

      {rulesList.map((rule, index) => (
        <Card
          key={index}
          variant="outlined"
          sx={{
            marginBottom: 2,
            border: "1px solid #E0E0E0",
            backgroundColor: "#fff",
            borderRadius: 2,
          }}
        >
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h6">{rule.type}</Typography>
                <Typography color="textSecondary">{rule.description}</Typography>
              </Box>
              <IconButton
                color="error"
                onClick={() => handleDeleteRule(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      ))}

      <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 1 }}>
        Add New Rule
      </Typography>

      <TextField
        label="Rule Type"
        variant="outlined"
        value={ruleType}
        onChange={(e) => setRuleType(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      <TextField
        label="Description"
        variant="outlined"
        multiline
        minRows={4}
        value={ruleDescription}
        onChange={(e) => setRuleDescription(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddRule}
        sx={{ marginBottom: 3 }}
      >
        Add Rule
      </Button>
    </Box>
  );
};

export default RulesComponent;
