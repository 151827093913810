import React from 'react'
import { StyledAddMatchButton } from '../../styles/StyledComponents'

export const AddMatchButton = ({label, icon, variant, component, onClick, ...props }) => {
  return (
    <StyledAddMatchButton
    variant={variant}
    component={component}
    startIcon={icon}
    onClick={onClick}
    {...props}
  >
  {label}
  </StyledAddMatchButton>
  )
}



