import { Box, Card, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';
import SportsBaseballOutlinedIcon from '@mui/icons-material/SportsBaseballOutlined';
import { CustomListItemText, HighlightedText, StyledChip, StyledTopPlayersHeading, StyledViewAll } from '../../styles/StyledComponents';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

const users = [
  { name: 'John Doe', avatar: '/images/svg/users/chaz-kuper.svg', points: 980, chip: '14.33', icon: <StarBorderOutlinedIcon sx={{ marginTop: '3px', marginLeft: '10px' }} /> },
  { name: 'Jane Smith', avatar: '/images/svg/users/luke-backhaus.svg', points: 980, chip: '14.33', icon: <StarIcon sx={{ marginTop: '3px', marginLeft: '10px', color: 'yellow' }} /> },
  { name: 'Alice Johnson', avatar: '/images/svg/users/chris-foster.svg', points: 980, chip: '14.33', icon: <StarBorderOutlinedIcon sx={{ marginTop: '3px', marginLeft: '10px' }} /> },
  { name: 'Bob Brown', avatar: '/images/svg/users/adeeb.svg', points: 980, chip: '14.33', icon: <StarBorderOutlinedIcon sx={{ marginTop: '3px', marginLeft: '10px' }} /> },
  { name: 'Charlie Davis', avatar:  '/images/svg/users/matta.svg', points: 980, chip: '14.33', icon: <StarBorderOutlinedIcon sx={{ marginTop: '3px', marginLeft: '10px' }} /> },
];

const TopPlayers = () => {
  const [showAll, setShowAll] = useState(false);

  const handleViewAllClick = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    setShowAll(prevShowAll => !prevShowAll);
  };

  return (
    <Card>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <img src="/images/svg/topPlayers.svg" alt='top Players' />
          <StyledTopPlayersHeading>Top Players</StyledTopPlayersHeading>
        </Stack>
        <StyledViewAll href='#' onClick={handleViewAllClick}>
          {showAll ? 'View Less' : 'View All'}
        </StyledViewAll>
      </Box>
      <Divider sx={{ width: '100%' }} />
      <Box>
        <List>
          {(showAll ? users : users.slice(0, 3)).map((user, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar alt={user.name} src={user.avatar} />
              </ListItemAvatar>
              <CustomListItemText
                primary={user.name}
                secondary={
                  <>
                    Points
                    <HighlightedText>{user.points}</HighlightedText>
                  </>
                }
              />
              <Stack direction="row" spacing={1} alignItems="center">
                <StyledChip label={user.chip} />
                {user.icon}
              </Stack>
            </ListItem>
          ))}
        </List>
      </Box>
    </Card>
  );
};

export default TopPlayers;
