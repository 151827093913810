import React, { useState } from "react";
import { Box, Button, TextField, Typography, Grid } from "@mui/material";
import axiosInstance from "../../utils/axios";
import { useToast } from "../../utils/ToastContext"; // Import useToast

const AddFacilityDrawer = ({ onClose, refreshFacilities }) => { // Add refreshFacilities as a prop
  const [facility, setFacility] = useState({
    name: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    description: "",
    courts: "",
    mapLink: "",
    rating: "",
  });

  const showToast = useToast(); // Initialize useToast

  const handleChange = (e) => {
    setFacility({
      ...facility,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.post("/createfacility", facility);
      if (response.status === 200) {
        showToast("Facility created successfully!", "success");
        refreshFacilities(); // Re-fetch facilities after successful creation
        onClose(); // Close drawer
      } else {
        showToast("Failed to create facility", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" gutterBottom>
        Add New Facility
      </Typography>
      <Grid container spacing={2}>
        {/* Show only the specified fields */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={facility.name}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street Address"
            name="streetAddress"
            value={facility.streetAddress}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={facility.city}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="State"
            name="state"
            value={facility.state}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Postal Code"
            name="postalCode"
            value={facility.postalCode}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={facility.description}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Courts"
            name="courts"
            value={facility.courts}
            onChange={handleChange}
            variant="outlined"
            type="number" // Number input for courts
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Map Link"
            name="mapLink"
            value={facility.mapLink}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Rating"
            name="rating"
            value={facility.rating}
            onChange={handleChange}
            variant="outlined"
            type="number" // Number input for rating
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Create Facility
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddFacilityDrawer;
