import React from "react";
import { StyledCreateTournamentInputButton } from "../../styles/StyledComponents";

const CreateTournamentButton = ({
  label,
  icon,
  variant,
  component,
  onClick,
  ...props
}) => {
  return (
    <>
      <StyledCreateTournamentInputButton
        variant={variant}
        component={component}
        startIcon={icon}
        onClick={onClick}
        {...props}
      >
        {label}
      </StyledCreateTournamentInputButton>
    </>
  );
};

export default CreateTournamentButton;
