import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Grid, MenuItem, TextField } from "@mui/material";
import DateComponent from "../Date/DateComponent";
import { AuthContext } from "../../context/Auth";
import axiosInstance from "../../utils/axios";

export default function TournamentDetailsTabs({
  onDataSubmit
}) {
  const { user } = useContext(AuthContext);
  const [tournamentName, setTournamentName] = useState("");
  const [leagueCoordinator, setLeagueCoordinator] = useState("");
  const [leagueCoach, setLeagueCoach] = useState(user.role === "admin" ? "" : user._id);
  const [leagueCost, setLeagueCost] = useState("");
  const [leagueFormat, setLeagueFormat] = useState("");
  const [leagueSeason, setLeagueSeason] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [coordinators, setCoordinators] = useState([]);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    axiosInstance.get("/getuserbyrole?role=coordinator")
      .then(response => {
        setCoordinators(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching coordinators:", error);
      });
  }, []);

  useEffect(() => {
    axiosInstance.get("/getuserbyrole?role=coach")
      .then(response => {
        setCoaches(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching coaches:", error);
      });
  }, []);

  useEffect(() => {
    const data = {
      name: tournamentName,
      startTime,
      endTime,
      leagueCoordinator,
      leagueCost,
      currency: "USD",
      leagueFormat,
      leagueSeason,
      coach: leagueCoach
    };
    onDataSubmit(data);
  }, [tournamentName, startTime, endTime, leagueCoordinator, leagueCost, leagueFormat, leagueSeason, leagueCoach, onDataSubmit]);

  return (
    <Box sx={{ padding: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            League Name
          </Typography>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter League Name"
            value={tournamentName}
            onChange={(e) => setTournamentName(e.target.value)}
            size="small"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            League Coordinator
          </Typography>
          <TextField
            select
            fullWidth
            value={leagueCoordinator}
            onChange={(e) => setLeagueCoordinator(e.target.value)}
            size="small"
          >
            {coordinators.map((coordinator) => (
              <MenuItem key={coordinator._id} value={coordinator._id}>
                {coordinator.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            League Cost (USD)
          </Typography>
          <TextField
            type="text"
            fullWidth
            placeholder="Enter Cost"
            value={leagueCost}
            onChange={(e) => setLeagueCost(e.target.value)}
            size="small"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            League Format
          </Typography>
          <TextField
            select
            fullWidth
            value={leagueFormat}
            onChange={(e) => setLeagueFormat(e.target.value)}
            size="small"
          >
            <MenuItem value="Double round-robin">Double round-robin</MenuItem>
            <MenuItem value="Single round-robin">Single round-robin</MenuItem>
            <MenuItem value="Knockout">Knockout</MenuItem>
            <MenuItem value="League">League</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            League Season
          </Typography>
          <TextField
            select
            fullWidth
            value={leagueSeason}
            onChange={(e) => setLeagueSeason(e.target.value)}
            size="small"
          >
            <MenuItem value="Spring">Spring</MenuItem>
            <MenuItem value="Summer">Summer</MenuItem>
            <MenuItem value="Autumn">Autumn</MenuItem>
            <MenuItem value="Winter">Winter</MenuItem>
          </TextField>
        </Grid>
        

        {user.role === "admin" ? (
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              League Coach
            </Typography>
            <TextField
              select
              fullWidth
              value={leagueCoach}
              onChange={(e) => setLeagueCoach(e.target.value)}
              size="small"
            >
              {coaches.map((coach) => (
                <MenuItem key={coach._id} value={coach._id}>
                  {coach.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : null}

        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            League Start Date
          </Typography>
          <DateComponent
            value={startTime}
            onChange={(newValue) => setStartTime(newValue)}
            sx={{
              width: '100%',
              height: '40px', // Same height as small TextField
              '& .MuiInputBase-root': {
                height: '40px',
              }
            }} // Added styles for consistent height and width
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: "500" }}>
            League End Date
          </Typography>
          <DateComponent
            value={endTime}
            onChange={(newValue) => setEndTime(newValue)}
            sx={{
              width: '100%',
              height: '40px', // Same height as small TextField
              '& .MuiInputBase-root': {
                height: '40px',
              }
            }} // Added styles for consistent height and width
          />
        </Grid>
      </Grid>
    </Box>
  );
}
