import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledMainContainer,
  StyledSpaceBetween,
} from "../../styles/StyledComponents";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { Box, Grid, Typography } from "@mui/material";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import axiosInstance from "../../utils/axios";
import Tournament from "../../components/Tournaments/Tournament";
import { AuthContext } from "../../context/Auth";
import CoachCard from "../../components/tournamentsDetailsCards/CoachCard";
import CoordinatorCard from "../../components/tournamentsDetailsCards/CoordinatorCard";
import LocationCard from "../../components/tournamentsDetailsCards/LocationCard";
import RulesCard from "../../components/tournamentsDetailsCards/RuleCard";
import CaptainCard from "../../components/tournamentsDetailsCards/CaptainCard";
import WeatherWidget from "../../components/Weather"

const TournamentDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedTournament, setSelectedTournament] = useState(null);
  const { isAdmin, user } = useContext(AuthContext);

  const fetchTournamentData = async () => {
    try {
      const tournamentDetailsResponse = await axiosInstance.get(
        `/tournament/${id}`
      );
      const tournamentDetails = tournamentDetailsResponse.data.body;
      setSelectedTournament(tournamentDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTournamentData();
    }
  }, [id]);

  return (
    <StyledMainContainer>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8.5} sx={{ marginTop: "50px" }}>
            <StyledSpaceBetween sx={{ marginBottom: "20px" }}>
              <WelcomeHeading label={"Welcome "+user.name} variant="h6" />
            </StyledSpaceBetween>
            
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              {selectedTournament ? (
                <Tournament
                  tournamentData={selectedTournament}
                  isAdmin={isAdmin}
                  playerId={user.id}
                  refreshTournamentData={fetchTournamentData}
                />
              ) : (
                <Typography variant="h6" color="textSecondary">
                  No available leagues to display
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <RulesCard rules={selectedTournament?.rules} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "86px",
              }}
            >
              <Grid item xs={12}>
                <WeatherWidget initialCity={selectedTournament?.facility?.state} />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <CoachCard coach={selectedTournament?.coach}/>
              </Grid>
              <Grid item xs={12}>
                <CoordinatorCard coordinator={selectedTournament?.leagueCoordinator}/>
              </Grid>
              <Grid item xs={12}>
                <CaptainCard captain={selectedTournament?.leagueCaptain}/>
              </Grid>
              <Grid item xs={12}>
                <LocationCard facility={selectedTournament?.facility}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledMainContainer>
  );
};

export default TournamentDetails;
