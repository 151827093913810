
import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableHeadCell } from '../../styles/StyledComponents';


 
const TableHeader = ({ headers }) => (
  <TableHead>
    <TableRow>
      {headers.map((header, index) => (
        <StyledTableHeadCell key={index}>{header}</StyledTableHeadCell>
      ))}
    </TableRow>
  </TableHead>
);



export default TableHeader;

