import React from 'react';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';

// Styled components
const PaginationRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageButton = styled(Button)`
  margin: 0 4px !important;
  min-width: 10px !important;
  min-height: 10px !important;

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.primary.main} !important;
    color: ${({ theme }) => theme.palette.common.white} !important;

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }

  &.MuiButton-outlined {
    border-color: grey !important;
    color: grey !important;
  }
`;

const PageNavigationButton = styled(Button)`
  text-transform: none !important;
`;

const CustomPaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <PaginationRoot>
      <PageNavigationButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
      >
        Prev
      </PageNavigationButton>
      {[...Array(Math.ceil(count / rowsPerPage)).keys()].map((pageNumber) => (
        <PageButton
          key={pageNumber}
          className={page === pageNumber ? 'Mui-selected' : ''}
          variant={page === pageNumber ? 'contained' : 'outlined'}
          onClick={(event) => onPageChange(event, pageNumber)}
          theme={theme}
        >
          {pageNumber + 1}
        </PageButton>
      ))}
      <PageNavigationButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        Next
      </PageNavigationButton>
    </PaginationRoot>
  );
};

const CustomTablePagination = (props) => {
  const { count, page, rowsPerPage, onPageChange, onRowsPerPageChange } = props;

  return (
    <TablePagination
      rowsPerPageOptions={[]} 
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={CustomPaginationActions}
      labelDisplayedRows={() => ''} 
    />
  );
};

const TablePaginationComponent = ({
  count,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  if (count === 0) {
    return null; 
  }
  return (
    <CustomTablePagination
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default TablePaginationComponent;
