import React from "react";
import { Box, Divider } from "@mui/material";
import CancelButton from "./CancelButton";
import { hover } from "@testing-library/user-event/dist/hover";

const ActionButtons = ({
  cancelLabel = "Cancel",
  nextLabel = "Next",
  onCancel,
  onNext,
  cancelButtonProps = {},
  nextButtonProps = {},
  containerStyle = {},
  dividerStyle = {},
  boxStyle = {},
}) => {
  return (
    <Box sx={{ marginTop: "auto", ...containerStyle }}>
      <Divider sx={{ marginBottom: "10px", ...dividerStyle }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "10px",
          ...boxStyle,
        }}
      >
        <CancelButton
          variant="outlined"
          sx={{
            marginRight: "10px",
            "&:hover": {
              border: "1px solid grey",
            },
            ...cancelButtonProps.sx,
          }}
          label={cancelLabel}
          onClick={onCancel}
          {...cancelButtonProps}
        />
        <CancelButton
          variant="contained"
          sx={{ marginRight: "10px", color: "#fff", ...nextButtonProps.sx }}
          label={nextLabel}
          onClick={onNext}
          {...nextButtonProps}
        />
      </Box>
    </Box>
  );
};

export default ActionButtons;
