import React from "react";
import {
  Container,
  Title,
  CustomList,
  CustomListItem,
  CustomListItemIcon,
  CustomListItemText,
  CustomListItemUpcomingText,
  UpComingCustomListItemText,
} from "../../styles/StyledComponents";
// import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack, Typography } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
const tournaments = [
  { name: "League 1", dates: "15/08/2024 - 22/09/2024" },
  { name: "League 2", dates: "13/10/2024 - 14/11/2024" },
  { name: "League 3", dates: "13/10/2024 - 14/11/2024" },
];
const UpcomingTournaments = () => {
  return (
    <Container>
      <Stack direction={"row"}>
        <Box>
          <CalendarTodayOutlinedIcon
            color="#fff"
            style={{
              backgroundColor: "#06B3D8",
              color: "#fff",
              padding: "5px",
              borderRadius: "2px",
            }}
          />
        </Box>
        &nbsp;<Typography>Upcoming Leagues</Typography>
      </Stack>
      <Stack>
        {tournaments.map((tournament, index) => (
          <Box key={index} sx={{ display: "flex", padding: "20px 0px" }}>
            <img
              src="/images/svg/tennis-icon.svg"
              alt="tennis icon"
              style={{ marginRight: "10px", marginTop: "-19px" }}
            />

            <UpComingCustomListItemText
              primary={tournament.name}
              secondary={
                <>
                  <CalendarMonthOutlinedIcon
                    fontSize={"10px"}
                    style={{ marginRight: 4 }}
                  />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ fontWeight: "bold", fontSize: "14px" }}
                  >
                    {tournament.dates}
                  </Typography>
                </>
              }
            />
          </Box>
        ))}
      </Stack>
    </Container>
  );
};

export default UpcomingTournaments;
