import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemText,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { red } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import { Label } from "@mui/icons-material";
import { Link } from "react-router-dom";

export const StyledCreateTournamentInputButton = styled(Button)(
  ({ theme, customVariant }) => ({
    height: "40px",
    textTransform: "capitalize",
  })
);
export const StyledWelcomeTypography = styled(Typography)(({ theme }) => ({
  color: "#303D58",
  textTransform: "capitalize",
  fontSize: "20px",
  fontWeight: "500",
  fontFamily: "Roboto, sans-serif",
}));

export const StyledAddMatchButton = styled(Button)(
  ({ theme, variant, customColor }) => ({
    backgroundColor:
      variant === "primary"
        ? theme.palette.primary.main
        : variant === "secondary"
        ? "#B0C6FE"
        : customColor || "transparent",
    height: "40px",
    color:
      variant === "primary"
        ? theme.palette.common.white
        : variant === "secondary"
        ? "#255BE8"
        : theme.palette.text.primary,
    textTransform: "capitalize",
    border: "1.5px solid #255BE8",
  })
);

export const StyledMainContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#EDF1F7",
  zIndex: 1000,
  padding: "10px 24px",
  height: "auto",
}));

export const StyledImageOutrbox = styled("div")(({ backgroundColor }) => ({
  backgroundColor: backgroundColor || "transparent",
  width: "100px",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
}));
export const StyledTotalTournamentsHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "18px",
  })
);
export const StyledTotalTournamentsNumber = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontFamily: "Roboto",
  fontWeight: 600,
  color: "#303D58",
}));

export const StyledTopPlayersHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  color: "#303D58",
}));

export const StyledViewAll = styled(Typography)(({ theme }) => ({
  color: "#255BE8",
  textDecoration: "none",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "500",
  cursor: "pointer",
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "#E8F3E8",
  fontWeight: 500,
  color: "#0F8B14",
  height: "26px",
}));

export const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiListItemText-primary": {
    fontSize: "0.775rem",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  "& .MuiListItemText-secondary": {
    fontSize: "0.675rem",
    color: theme.palette.text.secondary,
  },
}));

export const HighlightedText = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  color: "#303D58",
  // marginLeft: "5px",
}));
// Upcomint Tournament----------------------
export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1rem",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  fontFamily: "Roboto",
}));

export const CustomList = styled(List)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius,
}));

export const CustomListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderBottom: "none",
  },
  "&:nth-of-type(2n)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

//   export const CustomListItemUpcomingText = styled(ListItemText)(({ theme }) => ({
//     '& .MuiListItemText-primary': {
//       fontSize: '0.875rem',
//       fontWeight: '400',
//     },
//     '& .MuiListItemText-secondary': {
//       fontSize: '0.675rem',
//       color: "#303D58",
//       fontWeight: 'bold',
//     },
//   }));

export const UpComingCustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginTop: "-6px",
  "& .MuiListItemText-primary": {
    fontSize: "0.875rem",
    fontWeight: "bold",
    color: "#303D58",
  },
  "& .MuiListItemText-secondary": {
    display: "flex",
    alignItems: "center",
    fontSize: "0.675rem",
    color: "#303D58",
  },
}));

// TableComponent------------------------------
export const StyledTableMainContainer = styled(Card)({
  // padding: '20px',
});

export const StyledTableContainer = styled("div")({
  // marginTop: '20px',
  // boxShadow: 'none',
  // border: '1px solid #E0E0E0',
  // borderRadius: '8px',
});

export const StyledTable = styled(Table)({
  minWidth: 650,
});

export const StyledTableCell = styled(TableCell)({
  padding: "10px",
  borderBottom: "1px solid #E0E0E0",
  fontSize: "12px",
  color: "#303D58",
  fontWeight: "500",
});

export const StyledTableHeadCell = styled(StyledTableCell)({
  fontWeight: "bold",
  backgroundColor: "#F3F4F9",
  color: "#303D58",
  fontSize: "12px",
});
export const StyledTableCellText = styled(Typography)({
  fontWeight: "bold",
  // backgroundColor: "#F3F4F9",
  color: "#303D58",
  fontSize: "12px",
});
export const StyledHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 20px",
});

export const StyledTitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
  color: "#303D58",
  fontFamily: "Roboto",
});

export const StyledTableUserName = styled(Typography)({
  fontSize: "12px",
  fontWeight: "500",
  color: "#303D58",
});

export const StyledStartTournamentButton = styled(Button)({
  backgroundColor: "#A2A9B5",
  textTransform: "capitalize",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#A2A9B5",
  },
});

export const StyledScheduleDates = styled('div')`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #303d58;
  font-family: "Inter";
  font-weight: 500;

  .schedule-text {
    font-weight: bold;
    margin-left: 2px;
  }
`;

export const StyledAverageCell = styled(StyledTableCell)`
  //   background-color: ;
  /* border-radius: 8px; */

  .average {
    background-color: #e8f3e8;
    height: 28px;
    width: 60px;
    color: #0f8b14;
    font-size: 12px;
    font-weight: bold;
    border-radius: 18px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
  }
`;

//   space-between tag--------------------------------------
export const StyledSpaceBetween = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "30px",
  height: "30px",
  "&.profile-user": {
    width: "36px",
    height: "36px",
  },
}));

export const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  color: red[500],
  cursor: "pointer",
  fontSize: "20px",
  marginTop: "6px",
}));

export const StyledPagination = styled(TablePagination)`
  display: flex;
  justify-content: center;

  .MuiTablePagination-toolbar {
    padding-left: 8px;
    padding-right: 8px;
  }

  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiTablePagination-actions {
    margin-left: 0;
  }
`;

export const PaginationButton = styled(Button)`
  min-width: 36px;
  height: 36px;
  margin: 0 4px;
  padding: 0;
  border-radius: 50%;
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.main : "#ffffff"};
  color: ${({ theme, selected }) =>
    selected ? "#ffffff" : theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.dark : "#e3f2fd"};
  }

  &:disabled {
    background-color: #f1f1f1;
  }
`;

export const StyledBackTo = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "12px",
  color: "#255BE8",
}));

export const StyledMainCardContainer = styled(Card)(({ theme }) => ({
  paddingBottom: "10px",
  height: "100vh",
  position: "relative",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
}));

export const StyledTournamentTabsHeading = styled(Typography)(
  ({ theme, active }) => ({
    fontSize: "14px",
    padding: "10px 24px",
    cursor: "pointer",
    color: active ? "#255BE8" : "black",
    borderBottom: active ? "2px solid blue" : "2px solid transparent",
    transition: "color 0.3s, border-bottom 0.3s",
  })
);

export const StyledSelectDatesText = styled("div")(({ theme }) => ({
  fontSize: "14px",
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: "8px !important",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: "10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: "100px",
  maxWidth: "100%",
  padding: "8px 16px",
  fontSize: "12px",
  textAlign: "center",
}));

export const MatchInputContainer = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const TimePickerBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const TimePickerLabel = styled("p")`
  padding-top: 8px;
`;

export const CustomTextField = styled(TextField)`
  input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  border: "1px solid grey",
  color: "#000",
  height: "36px",
  padding: "10px",
}));

// Loging page------------------------------------------------------------------
export const StyledBackgroundImage = styled("div")`
  background-image: url("/images/login-hero-section.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  padding: 0px 30px;
  position: relative;
`;
export const StyledSignUpBackgroundImage = styled("div")`
  background-image: url("/images/login-hero-section.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative;

  @media (max-width: 1200px) {
    height: 500px;
  }

  @media (max-width: 992px) {
    height: 400px;
  }

  @media (max-width: 768px) {
    height: 300px;
  }

  @media (max-width: 576px) {
    height: 200px;
  }
`;

export const StyledLoginCard = styled(Card)(({ theme }) => ({
  padding: "15px 25px",
  width: "28%",
  maxWidth: "350px",
  borderRadius: "20px",
  position: "absolute",
  right: "156px",
  top: "60%",
  transform: "translateY(-50%)",
  height: "auto",
  maxHeight: "500px",
  overflow: "hidden",
  [theme.breakpoints.down("lg")]: {
    width: "40%",
    right: "80px",
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
    right: "60px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
    right: "10px",
    left: "10px",
    top: "30%",
    position: "relative",
    transform: "none",
    maxHeight: "500px",
    overflow: "auto",
  },
}));

export const StyledSignUpCard = styled(Card)(({ theme }) => ({
  padding: "15px 25px",
  width: "50%",
  maxWidth: "526px",
  borderRadius: "20px",
  position: "absolute",
  right: "50px",
  top: "54%",
  transform: "translateY(-50%)",
  height: "auto",
  // maxHeight: "500px",
  overflow: "hidden",
  [theme.breakpoints.down("lg")]: {
    width: "40%",
    right: "80px",
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
    right: "60px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
    right: "10px",
    left: "10px",
    top: "30%",
    position: "relative",
    transform: "none",
    maxHeight: "500px",
    overflow: "auto",
  },
}));

export const StyledTenniLeague = styled(Typography)`
  line-height: 26px;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: bold;
  .league {
    margin-left: 5px;
  }
`;

export const StyledSportsTennisIcon = styled(SportsTennisIcon)(({ theme }) => ({
  backgroundColor: "#255BE8",
  color: "#fff",
  height: "60px",
  width: "60px",
  marginRight: "5px",
  padding: "5px",
  borderRadius: "10px",
}));

export const StyledSignInHeading = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  margin: "0 0 24px 0px",
  fontSize: "30px",
  display:"flex",
  justifyContent:"center"
}));

// export const SignInCustomTextField = styled(TextField)(({ theme }) => ({
//   "& .MuiInputBase-root": {
//     height: "40px",
//     marginTop: "-10px",
//     marginBottom: "10px",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "gray",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "gray",
//     },
//   },
// }));

export const SignInCustomTextField = styled(TextField)(
  ({ theme }) => ({
    "& .MuiInputBase-root": {
      height: "34px",
      marginTop: "-10px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&.Mui-focused fieldset": {
        borderColor: "gray",
      },
    },
  })
);
export const CustomTextName = styled(TextField)(
  ({ theme }) => ({
    "& .MuiInputBase-root": {
      height: "56px",
      width:'240px'
    },
  })
);

export const SignInLabel = styled("label")(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Roboto",
}));

export const StyledForgotPassword = styled(Link)(({ theme }) => ({
  color: "#255BE8",
  fontSize: "14px",
  fontWeight: "500",
  fontFamily: "Roboto",
  textDecoration: "none",
}));

export const StyledDonthaveLink = styled(Link)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-decoration: none;

  .signUp {
    color: #255be8;
    margin-left: 5px;
    text-decoration: none;
  }
`;

export const StyledTableTextListItem = styled(ListItemText)(({ theme }) => ({
  "& .MuiListItemText-primary": {
    fontSize: "0.575rem",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  "& .MuiListItemText-secondary": {
    fontSize: "0.575rem",
    color: theme.palette.text.secondary,
  },
}));

export const StyledAddPlayersHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "16px",
  marginLeft: "16px",
}));

export const ADDAndCancelButtonSContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  position: "absolute",
  bottom: "0",
  right: "0",
  marginTop: "20px",
  marginRight: "0px",
  marginBottom: "10px",
}));

export const StyledLiveRightNowCard = styled(Card)(({ theme }) => ({
  backgroundImage: "url(/images/live-right-now-image.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "200px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const StyledLiveRightNow = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  color: "#fff",
  textTransform: "capitalize",
  lineHeight: "28px",
  fontWeight: 600,
  width: "100px",
  fontSize: "20px",
}));

export const StyledWatchNowButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#EF9F04",
  width: "100px",
  borderRadius: "20px",
  color: "#000",
  height: "30px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#EF9F04",
    color: "#fff",
  },
}));

export const StyledImage = styled("img")({
  width: "30px",
  marginRight: "10px",
});

export const StyledMatchCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "bold",
}));

export const StyledMatchCard = styled(Box)`
  width: 80px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-radius: 8px;
  background-color: #f4f5f8;
`;

export const StyledScoreCard = styled(StyledMatchCard)`
  background-color: ${(props) => props.bgColor || "#F4F5F8"};
  width: 80px;
  height: 120px;
  position: relative;
`;

export const StyledTotalScore = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "80px",
}));

export const AddSubPlayerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

export const AddSubPlayerHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MatchesContainer = styled(Box)`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
`;

export const StyledModalContainer = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  boxShadow: 24,
}));
export const StyledModalBox = styled(Box)(({ theme }) => ({
  padding: "20px 0px 0px 0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const StyledModaldescription = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: "16px",
  fontFamily: "Inter",
  width: "270px",
  fontWeight: 500,
  textAlign: "center",
  paddingTop: "10px",
}));

export const StyledCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: "#0F8B14",
  width: "55px",
  height: "55px",
}));

export const StyledDateBox = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "40px",
  mx: "10px",
  border: "1px solid #E8E9ED",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "3px",
  margin: "0px 10px",
}));

export const StyledArrowBox = styled(Box)(({ theme }) => ({
  width: "40px",
  height: "40px",
  border: "1px solid #E8E9ED",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "3px",
}));

export const SelectTournamentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const DatesContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`;

export const DateCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    font-size: 20px;
    margin-top: 5px;
    stroke-width: 0.5px;
  }
`;

export const SelectTournamentInput = styled(Select)`
  width: 250px;
  height: 40px;
  .MuiInputBase-root {
    padding: 5px;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    &:hover fieldset {
      border-color: #000;
    }
    &.Mui-focused fieldset {
      border-color: #3f51b5;
    }
  }
  .MuiInputBase-input {
    font-size: 16px;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  backgroundColor: "#F4F5F8",
  display: "flex",
  flexDirection: "column-reverse",
  padding: "15px",
  borderRadius: "10px",
  justifyContent: "space-between"
}));
