import React from "react";
import { Typography, Box, Avatar, Card, Divider, Tooltip } from "@mui/material";

const CoordinatorCard = ({ coordinator }) => {

  const trimEmail = (email) => {
    if (!email) return "";

    const [localPart, domain] = email.split("@");

    if (localPart.length > 12) {
      return `${localPart.substring(0, 12)}...@${domain}`;
    }

    return email;
  };

  return (
    <Card sx={{ p: 2, borderRadius: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar 
          src="/images/svg/tournament-icon.svg" 
          sx={{ mr: 1, width: 24, height: 24, borderRadius: 1 }} 
        />
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Coordinator
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar 
              src="/images/svg/avatar.svg" 
              sx={{ mr: 1, width: 32, height: 32 }} 
            />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {coordinator?.name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 3,marginTop:"10px" }}>
          <Box>
            <Typography sx={{ fontSize: 13, fontWeight: 400 }}>
              Email:
            </Typography>
            <Tooltip title={coordinator?.email || ""} arrow>
              <Typography sx={{ fontSize: 13, fontWeight: 700, cursor: "pointer" }}>
                {trimEmail(coordinator?.email)}
              </Typography>
            </Tooltip>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 13, fontWeight: 400 }}>
              Phone:
            </Typography>
            <Typography sx={{ fontSize: 13, fontWeight: 700 }}>
              {coordinator?.phoneNumber}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CoordinatorCard;
