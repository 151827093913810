import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Select,
  FormControl,
  Divider,
} from "@mui/material";
import {
  DateCheckbox,
  HighlightedText,
  StyledAddPlayersHeading,
  StyledTableTextListItem,
} from "../../styles/StyledComponents";
import CustomAvatar from "../Custom/CustomAvatar";
import ActionButtons from "../Buttons/ActionButtons";
import axiosInstance from "../../utils/axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "10px",
}));

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#EBECF3",
});

const StyledTable = styled(Table)({});

const AddPlayersDrawer = ({ onClose, setSelectedPlayers }) => {
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setDrawerSelectedPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axiosInstance.get("/players");
        let data = response.data.body;

        if (data.players && Array.isArray(data.players)) {
          data = data.players;
        }

        if (Array.isArray(data)) {
          const playersWithAvatars = data.map((player, index) => ({
            ...player,
            avatar: `https://randomuser.me/api/portraits/med/men/${
              index % 99
            }.jpg`,
          }));
          setPlayers(playersWithAvatars);
        } else {
          setError("API response is not an array");
        }
      } catch (error) {
        setError("Error fetching players");
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  const handleRowClick = (playerId) => {
    setDrawerSelectedPlayers((prevSelected) =>
      prevSelected.includes(playerId)
        ? prevSelected.filter((id) => id !== playerId)
        : [...prevSelected, playerId]
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    const selectedPlayerData = players.filter((player) =>
      selectedPlayers.includes(player._id)
    );
    setSelectedPlayers(selectedPlayerData);
    onClose();
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <StyledAddPlayersHeading variant="h6" component="h1" gutterBottom>
        Add Players
      </StyledAddPlayersHeading>

      <Divider sx={{ marginBottom: "20px" }} />

      <StyledTable>
        <StyledTableHead>
          <TableRow sx={{ height: "30px" }}>
            <StyledTableCell>Player Name</StyledTableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {players.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography align="center">No players available</Typography>
              </TableCell>
            </TableRow>
          ) : (
            players.map((player, i) => (
              <TableRow
                key={player._id}
                onClick={() => handleRowClick(player._id)}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: selectedPlayers.includes(player._id) ? '#e0e0e0' : 'inherit',
                  '&:hover': {
                    backgroundColor: selectedPlayers.includes(player._id) ? '#e0e0e0' : '#f0f0f0',
                  },
                }}
              >
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DateCheckbox
                      sx={{
                        width: '16px',
                        height: '16px',
                        marginRight: '5px',
                        marginTop: '5px',
                      }}
                      checked={selectedPlayers.includes(player._id)}
                      // Removed the onChange event here
                    />
                    <CustomAvatar
                      src={`https://randomuser.me/api/portraits/men/${
                        i + 1
                      }.jpg`}
                      alt={player.name}
                    />
                    <StyledTableTextListItem
                      sx={{
                        width: "80px",
                        marginLeft: "6px",
                        marginBottom: "0",
                      }}
                      primary={player.name}
                      secondary={
                        <HighlightedText>{player.email}</HighlightedText>
                      }
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </StyledTable>

      <ActionButtons
        cancelLabel="Cancel"
        nextLabel="Submit"
        onCancel={handleCancel}
        onNext={handleSubmit}
        cancelButtonProps={{
          sx: {
            width: "100px",
            marginRight: "10px",
            "&:hover": {
              border: "1px solid grey",
            },
          },
        }}
        nextButtonProps={{
          sx: {
            backgroundColor: "#255BE8",
            border: "none",
            color: "#fff",
            width: "100px",
          },
        }}
        containerStyle={{ marginTop: "20px", paddingBottom: "10px" }}
        dividerStyle={{ marginBottom: "15px" }}
      />
    </Box>
  );
};

export default AddPlayersDrawer;
