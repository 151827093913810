import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "../utils/axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = localStorage.getItem("user");
    if (token && userData) {
      setUser({ ...JSON.parse(userData), token });
    }
    setLoading(false);
  }, []);

  const signup = async (name, email, password, phoneNumber, role) => {
    try {
      const response = await axiosInstance.post("/auth/signup", {
        name,    
        email,    
        password,   
        phoneNumber,  
        role, 
      });
      const { token } = response.data;
  
      localStorage.setItem("token", token);
      localStorage.setItem(
        "user",
        JSON.stringify({ name, email, phoneNumber, role })
      );
      setUser({ name, email, phoneNumber, role, token });
    } catch (error) {
      throw new Error(error.response?.data?.message || "Sign-up failed");
    }
  };
  

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post("/auth/login", {
        email,
        password,
      });
      const { data } = response.data;
      const token = data.token;

      localStorage.setItem("token", token);
      const response2 = await axiosInstance.get("/player");
      const userData = response2.data;
      localStorage.setItem("user", JSON.stringify(userData));
      setUser({ email, token, id: userData.id , role: userData.role });
      return true;
    } catch (error) {
      console.error("Login Error:", error);
      throw new Error(error.response?.data?.message || "Login failed");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
  };

  const updateSelectedPlayerData = (players) => {
    setSelectedPlayerData(players);
  };

  const isAdmin = user?.role === 'admin';
  const isCoach = user?.role === 'coach';
  const isCoordinator = user?.role === 'coordinator';

  return (
    <AuthContext.Provider
      value={{
        user,
        signup,
        login,
        logout,
        loading,
        selectedPlayerData,
        updateSelectedPlayerData,
        isAdmin,
        isCoach,
        isCoordinator
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
