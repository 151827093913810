import React, { useContext } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AddPlayersDrawer from "./AddPlayersDrawer";
import AddScoreSwipeableDrawer from "./AddScoreSwipeableDrawer";
import AddSubPlayersDrawer from "./AddSubPlayersDrawer";
import AddFacilityDrawer from "./AddFacilityDrawer";
import EditFacilityDrawer from "./EditFacilityDrawer";
import { AuthContext } from "../../context/Auth";

const SwipeableDrawerComponent = ({
  open,
  onClose,
  component,
  setSelectedPlayers,
  matches,
  players,
  onSubmit,
  matchId,
  playerId,
  matchName,
  facilityData, // For Edit Facility
  refreshFacilities, // New prop for refreshing the facilities
}) => {
  const { isAdmin } = useContext(AuthContext);
  const handleDrawerClick = (event) => {
    event.stopPropagation();
  };

  const renderComponent = () => {
    switch (component) {
      case "addPlayers":
        return (
          <AddPlayersDrawer
            onClose={onClose}
            setSelectedPlayers={setSelectedPlayers}
          />
        );
      case "addScore":
        return (
          <AddScoreSwipeableDrawer
            onClose={onClose}
            matches={matches}
            players={players}
            onSubmit={onSubmit}
          />
        );
      case "addSubPlayers":
        return (
          <AddSubPlayersDrawer
            onClose={onClose}
            matchId={matchId}
            playerId={playerId}
            matchName={matchName}
            onSubmit={onSubmit}
            isAdmin={isAdmin}
          />
        );
      case "add-facility":
        return (
          <AddFacilityDrawer
            onClose={onClose}
            refreshFacilities={refreshFacilities} // Pass refresh function to Add Facility
          />
        );
      case "edit-facility":
        return (
          <EditFacilityDrawer
            onClose={onClose}
            facilityData={facilityData}
            refreshFacilities={refreshFacilities} // Pass refresh function to Edit Facility
          />
        );
      default:
        return null;
    }
  };

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={onClose} onOpen={() => {}}>
      <Box
        sx={{ width: 350, padding: "80px 0px 0px 0px" }}
        role="presentation"
        onClick={handleDrawerClick}
        onKeyDown={handleDrawerClick}
      >
        {renderComponent()}
      </Box>
    </SwipeableDrawer>
  );
};

export default SwipeableDrawerComponent;
