import React, { useState, useEffect } from "react";
import {
  StyledTableCell,
  StyledTableHeadCell,
} from "../../styles/StyledComponents";
import {
  IconButton,
  TableRow,
  Typography,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../../utils/axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { LEAGUES_STATUS } from "../../utils/constants";
import { useToast } from "../../utils/ToastContext";

const TournamentsList = ({ status }) => {
  const navigate = useNavigate();
  const showToast = useToast();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const headers = [
    { key: "name", title: "Name" },
    { key: "status", title: "Status" },
    { key: "createdAt", title: "Created On" },
  ];

  const fetchTournaments = async () => {
    try {
      const response = await axiosInstance.get("/tournaments");
      const responseData = response.data.body || response.data;

      if (Array.isArray(responseData)) {
        setData(
          responseData.filter(
            (tournament) =>
              headers.every((header) =>
                tournament.hasOwnProperty(header.key)
              ) && tournament.status === status
          )
        );
      } else if (
        responseData.tournaments &&
        Array.isArray(responseData.tournaments)
      ) {
        const filteredData = responseData.tournaments.filter(
          (tournament) =>
            headers.every((header) =>
              tournament.hasOwnProperty(header.key)
            ) && tournament.status === status
        );
        setData(filteredData);
      } else {
        console.error("Unexpected API response format:", responseData);
        setError("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error fetching tournaments data:", error);
      setError("Error fetching tournaments data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournaments();
  }, [status]);

  const handleView = (tournamentId) => {
    navigate(`/leagues/${tournamentId}`);
  };

  const handleDelete = async (tournamentId) => {
    try {
      const response = await axiosInstance.put("/tournament/status", {
        status: LEAGUES_STATUS.DELETE,
        tournamentId: tournamentId,
      });

      if (response.status === 200) {
        setData(data.filter((tournament) => tournament.id !== tournamentId));
        showToast("League deleted successfully", "success");
        fetchTournaments();
        console.log("League deleted successfully.");
      } else {
        console.error("Failed to update tournament status.");
      }
    } catch (error) {
      console.error("Error deleting tournament:", error);
    }
  };

  const renderCellValue = (value, headerKey) => {
    if (headerKey === "createdAt" && value) {
      return dayjs(value).format("DD MMM YYYY");
    }
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value;
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <StyledTableHeadCell key={header.key}>
              {header.title}
            </StyledTableHeadCell>
          ))}
          <StyledTableHeadCell>Actions</StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((tournament) => (
          <TableRow
            key={tournament.tournamentId || tournament._id}
            hover
            onClick={() => handleView(tournament._id)}
            style={{ cursor: "pointer" }}
          >
            {headers.map((header) => (
              <StyledTableCell key={header.key}>
                {renderCellValue(tournament[header.key], header.key)}
              </StyledTableCell>
            ))}
            <StyledTableCell>
              <IconButton
                color="#303D58"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDelete(tournament._id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TournamentsList;
