import React from "react";
import { Typography, Box, Card, Avatar, Divider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const LocationCard = ({ facility }) => {

  return (
    <Card sx={{p: 2, borderRadius: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src="/images/svg/location.svg"
          sx={{ mr: 1, width: 24, height: 24, borderRadius: 1 }}
        />
        <Typography variant="body1" sx={{ fontWeight: "bold", color: "#00bcd4" }}>
          Location
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pr: 3 }}>
        <Typography sx={{ color: "#303D58", fontSize: 15, fontWeight: 700 }}>
          {facility?.name}
        </Typography>
        <Typography sx={{ color: "#303D58", fontSize: 15, fontWeight: 400 }}>
          {facility?.streetAddress + ", "+ facility?.city+", "+ facility?.state+", "+ facility?.postalCode}
        </Typography>
        <Typography variant="body2">
          <strong>Courts: {facility?.courts}</strong>
        </Typography>
        
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Typography variant="body2">
            <strong>Rating:</strong> {facility?.rating}
          </Typography>
          <StarIcon sx={{ color: "#fbc02d", ml: 0.5 }} />
        </Box>
      </Box>
    </Card>
  );
};

export default LocationCard;
