import React, { useState, useContext } from "react";
import {
  Button,
  Box,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  SignInCustomTextField,
  SignInLabel,
  StyledSignUpBackgroundImage,
  StyledSignUpCard,
  StyledSignInHeading,
  StyledDonthaveLink,
} from "../../styles/StyledComponents";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EastIcon from "@mui/icons-material/East";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [role, setRole] = useState("coordinator");
  const [errors, setErrors] = useState({});
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    const confirmPassword = event.target.confirmPassword.value;
    const firstName = event.target.firstName.value;
    const phoneNumber = event.target.phoneNumber.value;

    const newErrors = {};
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!email) newErrors.email = "Email Address is required";
    if (!password) newErrors.password = "Password is required";
    if (!phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    if (password !== confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await signup(firstName, email, password, phoneNumber, role);
      navigate("/login");
    } catch (error) {
      setErrors({ general: "Sign-up failed. Please try again." });
    }
  };

  return (
    <StyledSignUpBackgroundImage>
      <StyledSignUpCard>
        <img
          src="/images/logo.png"
          alt="sign-up icon"
          className="login-icon"
        />
        <StyledSignInHeading component="h1" variant="h5">
          Sign up
        </StyledSignInHeading>

        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <SignInLabel>Select Role</SignInLabel>
              <TextField
                select
                fullWidth
                value={role}
                onChange={(e) => setRole(e.target.value)}
                size="small"
              >
                <MenuItem value="coordinator">Coordinator</MenuItem>
                <MenuItem value="coach">Coach</MenuItem>
                <MenuItem value="player">Player</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <SignInLabel>Full Name</SignInLabel>
              <SignInCustomTextField
                margin="normal"
                required
                fullWidth
                id="firstName"
                name="firstName"
                autoComplete="first-name"
                placeholder="First Name"
                autoFocus
                type="text"
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SignInLabel>Phone Number</SignInLabel>
              <SignInCustomTextField
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                autoComplete="phone-number"
                placeholder="Phone Number"
                autoFocus
                type="text"
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SignInLabel>Email address</SignInLabel>
              <SignInCustomTextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email Address"
                type="email"
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SignInLabel>Password</SignInLabel>
              <SignInCustomTextField
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                autoComplete="current-password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SignInLabel>Confirm Password</SignInLabel>
              <SignInCustomTextField
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up <EastIcon sx={{ ml: 3 }} />
              </Button>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <StyledDonthaveLink href="#" variant="body2" to="/login">
                {" Already have an account?"}
                <span className="signUp">Sign in</span>
              </StyledDonthaveLink>
            </Grid>
          </Grid>
        </Box>
      </StyledSignUpCard>
    </StyledSignUpBackgroundImage>
  );
};

export default SignUp;
