import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledMainContainer,
  StyledSpaceBetween,
} from "../../styles/StyledComponents";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { Box, Grid, Typography } from "@mui/material";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import CreateTournamentButton from "../../components/Buttons/CreateTournamentButton";
import CardComponent from "../../components/Dashboard/CardComponent";
import TopPlayers from "../../components/Dashboard/TopPlayers";
import UpcomingTournaments from "../../components/Tournaments/UpcomingTournaments";
import LiveRightNow from "../../components/Dashboard/LiveRightNow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axiosInstance from "../../utils/axios";
import Tournament from "../../components/Tournaments/Tournament";
import { AuthContext } from "../../context/Auth";
import { LEAGUES_STATUS } from "../../utils/constants";

const Dashboard = () => {
  const navigate = useNavigate();
  const [totalTournaments, setTotalTournaments] = useState(0);
  const [registeredPlayers, setRegisteredPlayers] = useState(0);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const { isAdmin, user } = useContext(AuthContext);

  const fetchTournamentsAndPlayers = async () => {
    try {
      const tournamentsResponse = await axiosInstance.get("/tournaments");
      const tournamentsData = tournamentsResponse.data.body || [];
      setTotalTournaments(tournamentsData.length);

      const playersResponse = await axiosInstance.get("/players");
      const playersData = playersResponse.data.players || [];
      setRegisteredPlayers(playersData.length);

      const availableTournament = tournamentsData.find(
        (tournament) =>
          tournament.status === LEAGUES_STATUS.STARTED || tournament.status === LEAGUES_STATUS.CREATED
      );
      console.log(availableTournament)

      if (availableTournament) {
        const tournamentDetailsResponse = await axiosInstance.get(
          `/tournament/${availableTournament._id}`
        );
        const tournamentDetails = tournamentDetailsResponse.data.body;
        setSelectedTournament(tournamentDetails);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTournamentsAndPlayers();
  }, []);

  return (
    <StyledMainContainer>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />

        <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
          {isAdmin ? (
            <Grid item xs={12}>
              <StyledSpaceBetween>
                <WelcomeHeading label={"Welcome "+user.name} variant="h6" />
                <CreateTournamentButton
                  label="Create League"
                  icon={<AddCircleIcon />}
                  component="label"
                  variant="contained"
                  onClick={() => navigate("/leagues/create")}
                />
              </StyledSpaceBetween>
            </Grid>
          ) : null}

          <Grid item xs={12} md={8.5}>
            {selectedTournament ? (
              <Tournament
                tournamentData={selectedTournament}
                isAdmin={isAdmin}
                playerId={user.id}
                refreshTournamentData={fetchTournamentsAndPlayers}
              />
            ) : (
              <Typography variant="h6" color="textSecondary">
                No available leagues to display
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                // marginTop: "-178px",
              }}
            >
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <TopPlayers />
              </Grid>
              <Grid item xs={12}>
                <UpcomingTournaments />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledMainContainer>
  );
};

export default Dashboard;
