import React from "react";
import { Typography, Box, Card, Avatar, Divider } from "@mui/material";

const RulesCard = ({ rules }) => {
  return (
    <Card sx={{ p: 2, borderRadius: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src="/images/svg/folder.svg"
          sx={{ mr: 1, width: 24, height: 24, borderRadius: 1 }}
        />
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Rules
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />
      {rules && rules.map((rule, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Avatar
            src="/images/svg/tournaments-sidenavbar-icon.svg"
            sx={{ mr: 1, width: 24, height: 24, borderRadius: 1 }}
          />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {rule.type}
            </Typography>
            <Typography variant="body2">{rule.description}</Typography>
          </Box>
        </Box>
      ))}
    </Card>
  );
};

export default RulesCard;
