import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledMainContainer,
  StyledSpaceBetween,
} from "../../styles/StyledComponents";
import SideNavbar from "../../components/Navbar/SideNavbar";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import TopPlayers from "../../components/Dashboard/TopPlayers";
import UpcomingTournaments from "../../components/Tournaments/UpcomingTournaments";
import { AuthContext } from "../../context/Auth";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { yellow } from "@mui/material/colors";
import SwipeableDrawerComponent from "../../components/Drawer/SwipeableDrawerComponent";
import axiosInstance from "../../utils/axios";

const FacilitySelection = () => {
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState("");
  const [facilityData, setFacilityData] = useState(null);

  const { isAdmin, user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFacilities();
  }, []);

  const fetchFacilities = async () => {
    try {
      const response = await axiosInstance.get("/getfacilities");
      if (response.status === 200) {
        setFacilities(response.data.data);
      } else {
        console.error("Failed to fetch facilities");
      }
    } catch (error) {
      console.error("Error fetching facilities:", error);
    }
  };

  const handleSelection = (event) => {
    setSelectedFacility(event.target.value);
  };

  const handleOpenDrawer = (component, facility = null) => {
    setDrawerComponent(component);
    setFacilityData(facility);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerComponent("");
    setFacilityData(null);
  };
  const refreshFacilities = () => {
    fetchFacilities();
  };

  const handleDelete = async (facilityId) => {
    try {
      const response = await axiosInstance.delete("/deletefacility", {
        data: { id: facilityId },
      });
      if (response.status === 200) {
        console.log("Facility deleted successfully");
        fetchFacilities(); // Refresh the list after deleting
      } else {
        console.error("Failed to delete facility");
      }
    } catch (error) {
      console.error("Error deleting facility:", error);
    }
  };

  return (
    <StyledMainContainer>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />
        <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
          <Grid item xs={12}>
            <StyledSpaceBetween>
              <WelcomeHeading label="Facilities" variant="h6" />
            </StyledSpaceBetween>
          </Grid>
          <Grid
            item
            xs={12}
            md={8.5}
            sx={{
              backgroundColor: "#ffffff",
              marginTop: "16px",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
                marginRight: "16px",
              }}
            >
              <Box>
                <Button
                  onClick={() => handleOpenDrawer("add-facility")}
                  sx={{
                    display: "inline-flex",
                    height: "40px",
                    padding: "12px 16px",
                    alignItems: "center",
                    gap: "8px",
                    flexShrink: 0,
                    borderRadius: "8px",
                    background: "#255BE8",
                    color: "#ffffff",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#1e4f9e",
                    },
                  }}
                >
                  Add New Facility
                </Button>
              </Box>
            </Box>
            <Box sx={{ margin: "0px 16px 0 0px", backgroundColor: "#ffffff" }}>
              <FormControl component="fieldset" sx={{ width: "100%" }}>
                <RadioGroup value={selectedFacility} onChange={handleSelection}>
                  {facilities.map((facility) => (
                    <Card
                      key={facility._id}
                      variant="outlined"
                      sx={{
                        marginBottom: 2,
                        border:
                          selectedFacility === facility._id.toString()
                            ? "2px solid #1976D2"
                            : "1px solid #E0E0E0",
                        backgroundColor:
                          selectedFacility === facility._id.toString()
                            ? "#E3F2FD"
                            : "#F8FAFC",
                        borderRadius: 2,
                      }}
                    >
                      <CardContent>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              sx={{ fontSize: "15px", fontWeight: "600" }}
                            >
                              {facility.name}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "15px", fontWeight: "400" }}
                            >
                              {facility.streetAddress}, {facility.city},{" "}
                              {facility.state}, {facility.postalCode}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "15px", fontWeight: "400" }}
                            >
                              No of Courts: {facility.courts}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body1"
                              sx={{
                                marginRight: 1,
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#303D58",
                              }}
                            >
                              Rating: {facility.rating}/5
                            </Typography>
                            <StarIcon sx={{ color: yellow[700] }} />
                          </Box>
                          <Box>
                            <Button
                              onClick={() =>
                                handleOpenDrawer("edit-facility", facility)
                              }
                              sx={{
                                color: "#255BE8",
                                fontSize: "15px",
                                fontWeight: "600",
                                textTransform: "none",
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => handleDelete(facility._id)}
                              sx={{
                                color: "#255BE8",
                                fontSize: "15px",
                                fontWeight: "600",
                                textTransform: "none",
                              }}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <TopPlayers />
              </Grid>
              <Grid item xs={12}>
                <UpcomingTournaments />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Swipeable Drawer for Add/Edit Facility */}
      <SwipeableDrawerComponent
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        component={drawerComponent}
        facilityData={facilityData}
        refreshFacilities={refreshFacilities}
      />
    </StyledMainContainer>
  );
};

export default FacilitySelection;
