import React, { useState, useContext } from "react";
import { Button, Box, InputAdornment, IconButton, Grid } from "@mui/material";
import {
  SignInCustomTextField,
  SignInLabel,
  StyledSignUpBackgroundImage,
  StyledSignInHeading,
  StyledDonthaveLink,
  StyledSignUpCard,
  StyledBackgroundImage,
  StyledLoginCard,
} from "../../styles/StyledComponents";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EastIcon from "@mui/icons-material/East";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    const newErrors = {};
    if (!email) newErrors.email = "Email Address is required";
    if (!password) newErrors.password = "Password is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const result = await login(email, password);
      if(result) {
        navigate("/");
      }
    } catch (error) {
      setErrors({
        email: "Invalid email or password",
        password: "Invalid email or password",
      });
    }
  };

  return (
    <StyledBackgroundImage>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <StyledLoginCard>
            <img
              src="/images/logo.png"
              alt="sign-in icon"
              className="login-icon"
            />
            <StyledSignInHeading component="h1" variant="h5">
              Sign in
            </StyledSignInHeading>
            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
              onSubmit={handleSubmit}
            >
              <Box>
                <SignInLabel>Email address</SignInLabel>
                <SignInCustomTextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="Email Address"
                  type="email"
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Box>
              <Box>
                <SignInLabel>Password</SignInLabel>
                <SignInCustomTextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  name="password"
                  autoComplete="off"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In <EastIcon sx={{ ml: 3 }} />
              </Button>
              <Box textAlign="center">
                <StyledDonthaveLink variant="body2" to="/signup">
                  {" Don't have an account?"}{" "}
                  <span className="signUp">
                    Sign up
                  </span>
                </StyledDonthaveLink>
              </Box>
            </Box>
          </StyledLoginCard>
        </Grid>
      </Grid>
    </StyledBackgroundImage>
  );
};

export default SignIn;
