import { TableBody, Box, IconButton, Typography, TableRow, TableCell, TextField, MenuItem, Grid } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledTable, StyledTableCellText } from '../../styles/StyledComponents';
import TableHeader from '../Table/TableHeader';
import TablePaginationComponent from '../Pagination/TablePaginationComponent';
import { AddMatchButton } from '../Buttons/AddMatchButton';
import AddIcon from "@mui/icons-material/Add";
import SwipeableDrawerComponent from '../Drawer/SwipeableDrawerComponent';

const headers = [
  'Player Name',
  'Email',
  '',
];

const AddPlayersTab = ({ selectedPlayers, setSelectedPlayers, onCaptainSelect }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState('');
  const [selectedCaptain, setSelectedCaptain] = useState('');

  const handleOpenDrawer = (component) => {
    setDrawerComponent(component);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerComponent('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRemovePlayer = (playerId) => {
    const updatedPlayers = selectedPlayers.filter(player => player._id !== playerId);
    setSelectedPlayers(updatedPlayers);
    // If the removed player was the selected captain, clear the captain selection
    if (playerId === selectedCaptain) {
      setSelectedCaptain('');
      onCaptainSelect('');
    }
  };

  const handleCaptainSelectChange = (e) => {
    const selectedId = e.target.value;
    setSelectedCaptain(selectedId);
    onCaptainSelect(selectedId);
  };

  return (
    <div>

      <Grid container spacing={1}>
        <Box sx={{ m: 4 }}>
          <AddMatchButton
            label="Add Players"
            icon={<AddIcon />}
            component="label"
            variant="secondary"
            onClick={() => handleOpenDrawer('addPlayers')}
          />
        </Box>
        <Grid item xs={6}>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            League Captain
          </Typography>
          <TextField
            select
            fullWidth
            value={selectedCaptain}
            onChange={handleCaptainSelectChange}
            size="small"
          >
            {selectedPlayers.map((player) => (
              <MenuItem key={player._id} value={player._id}>
                {player.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <StyledTable>
        <TableHeader headers={headers} />
        <TableBody>
          {selectedPlayers.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography align="center">No players selected.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            selectedPlayers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((player) => (
              player ? (
                <TableRow key={player._id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img 
                        src={player.avatar || '/path/to/default/image.png'} 
                        alt={player.name} 
                        style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 8 }}
                      />
                      <StyledTableCellText>{player.name || 'N/A'}</StyledTableCellText>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <StyledTableCellText>{player.email || 'N/A'}</StyledTableCellText>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRemovePlayer(player._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : null
            ))
          )}
        </TableBody>
      </StyledTable>

      <SwipeableDrawerComponent
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        component={drawerComponent}
        setSelectedPlayers={setSelectedPlayers}
      />

      <Box sx={{ mt: 3 }}>
        <TablePaginationComponent
          count={selectedPlayers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default AddPlayersTab;
