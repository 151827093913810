import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, TextField, MenuItem, Box, Divider } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ActionButtons from "../Buttons/ActionButtons";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  paddingBottom: "10px",
});

const Content = styled("div")({
  flex: 1,
  overflowY: "auto",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px",
  borderBottom: "1px solid #e0e0e0",
});

const Player = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
});

const AddScoreHeading = styled(Typography)({
  fontSize: "16px",
  color: "#303D58",
  fontFamily: "Inter",
  fontWeight: 550,
});

const StyledDateTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
    height: "40px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ccc",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#888",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#3f51b5",
  },
});

const StyledNumberTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    width: "50px",
    height: "30px",
  },
});

const StyledAddPoints = styled(Typography)({
  color: "#000",
  marginTop: "5px",
  marginRight: "8px",
  fontSize: "14px",
});

const StyledAddScorUserImage = styled("img")({
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  marginTop: "5px",
  marginRight: "5px",
});

const AddScoreSwipeableDrawer = ({
  onClose,
  matches,
  players,
  onSubmit,
  isAdmin,
}) => {
  const [selectedMatch, setSelectedMatch] = useState(matches[0]?._id || "");
  const [playerScores, setPlayerScores] = useState({});

  const handleMatchChange = (event) => {
    setSelectedMatch(event.target.value);
  };

  const handleScoreChange = (playerId, score) => {
    setPlayerScores((prevScores) => ({
      ...prevScores,
      [playerId]: score,
    }));
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    const scores = players.map((player) => ({
      playerId: player._id,
      score: playerScores[player._id] || 0,
    }));

    onSubmit(selectedMatch, scores);
    onClose();
  };

  // Filter available matches based on player's participation
  const availableMatches = matches.filter((match) =>
    match.playerDetails?.some((player) =>
      players.some(
        (playerInfo) => playerInfo._id === player.id && player.availability
      )
    )
  );

  // Filter players for the selected match
  const playersInSelectedMatch = matches
    .find((match) => match._id === selectedMatch)?.playerDetails?.filter(
      (player) =>
        players.some(
          (playerInfo) => playerInfo._id === player.id && player.availability
        )
    ) || [];

  return (
    <Container>
      <Content>
        <Header>
          <AddScoreHeading variant="h6">Add Score</AddScoreHeading>
          <StyledDateTextField
            select
            value={selectedMatch}
            onChange={handleMatchChange}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <CalendarMonthOutlinedIcon
                  position="start"
                  sx={{ marginRight: "10px" }}
                />
              ),
            }}
          >
            {isAdmin
              ? matches.map((match) => (
                  <MenuItem key={match._id} value={match._id}>
                    {match.name}
                  </MenuItem>
                ))
              : availableMatches.map((match) => (
                  <MenuItem key={match._id} value={match._id}>
                    {match.name}
                  </MenuItem>
                ))}
          </StyledDateTextField>
        </Header>
        {playersInSelectedMatch.map((player, i) => (
          <React.Fragment key={player.id}>
            <Player>
              <Box sx={{ marginTop: "8px", display: "flex" }}>
                <StyledAddScorUserImage
                  src={`https://randomuser.me/api/portraits/men/${i + 1}.jpg`}
                  alt={player.name}
                />
                <StyledAddPoints>{player.name}</StyledAddPoints>
              </Box>
              <Box display="flex">
                <StyledAddPoints
                  variant="outlined"
                  size="small"
                  sx={{ fontWeight: "500" }}
                >
                  Add Points
                </StyledAddPoints>
                <StyledNumberTextField
                  variant="outlined"
                  size="small"
                  value={playerScores[player.id] || ""}
                  onChange={(e) =>
                    handleScoreChange(
                      player.id,
                      parseInt(e.target.value, 10) || 0
                    )
                  }
                />
              </Box>
            </Player>
            <Divider />
          </React.Fragment>
        ))}
      </Content>

      <ActionButtons
        cancelLabel="Cancel"
        nextLabel="Submit"
        onCancel={handleCancel}
        onNext={handleSubmit}
        cancelButtonProps={{
          sx: {
            width: "100px",
            marginRight: "10px",
            "&:hover": {
              border: "1px solid grey",
            },
          },
        }}
        nextButtonProps={{
          sx: {
            backgroundColor: "#255BE8",
            border: "none",
            color: "#fff",
            width: "100px",
          },
        }}
        containerStyle={{ marginTop: "20px", paddingBottom: "10px" }}
        dividerStyle={{ marginBottom: "15px" }}
      />
    </Container>
  );
};

export default AddScoreSwipeableDrawer;
