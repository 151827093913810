import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Tooltip,
  TableHead,
  TableBody,
  TableRow,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import {
  StyledCancelIcon,
  StyledButton,
  StyledHeader,
  StyledScheduleDates,
  StyledTable,
  StyledTableMainContainer,
  StyledTitle,
  StyledImage,
  StyledAverageCell,
  StyledTableCell,
  StyledTableUserName,
  StyledTableHeadCell,
} from "../../styles/StyledComponents";
import TablePaginationComponent from "../Pagination/TablePaginationComponent";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CustomAvatar from "../Custom/CustomAvatar";
import SwipeableDrawerComponent from "../Drawer/SwipeableDrawerComponent";
import { useToast } from "../../utils/ToastContext";
import { LEAGUES_STATUS } from "../../utils/constants";
import PostAddIcon from '@mui/icons-material/PostAdd';

const Tournament = ({
  tournamentData,
  isAdmin,
  playerId,
  refreshTournamentData,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [status, setStatus] = useState(tournamentData.status);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("USD");
  const showToast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setStatus(tournamentData.status);
    fetchPaymentData();
  }, [tournamentData.status]);

  const fetchPaymentData = async () => {
    try {
      const response = await axiosInstance.get(`/getpayment?tournamentId=${tournamentData.id}`);
      if (response.status === 200) {
        setPaymentData(response.data.data);
      } else {
        showToast("Failed to fetch payment data", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  const handleOpenDrawer = (component) => {
    setDrawerComponent(component);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerComponent("");
  };

  const handleClick = (event, playerId) => {
    setAnchorEl(event.currentTarget);
    setSelectedPlayer(playerId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedPlayer(null);
  };

  const handleReplace = () => {
    handleClose();
    navigate(`/replace/${selectedPlayer}/${tournamentData.id}`);
  };

  const handleReplaceRequest = () => {
    handleClose();
    navigate(`/request/${selectedPlayer}/${tournamentData.id}`);
  };

  const handleEdit = () => {
    handleClose();
    navigate(`/status/${selectedPlayer}/${tournamentData.id}`);
  };

  const handleDelete = async () => {
    handleClose();
    try {
      const response = await axiosInstance.delete("/removeplayersfromtournament", {
        data: {
          id: tournamentData.id,
          players: [selectedPlayer],
        },
      });

      if (response.status === 200) {
        showToast("Player removed successfully!", "success");
        refreshTournamentData();
      } else {
        showToast("Failed to remove player", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startTournament = async () => {
    try {
      const response = await axiosInstance.put("/tournament/status", {
        status: LEAGUES_STATUS.STARTED,
        tournamentId: tournamentData.id,
      });

      if (response.status === 200) {
        setStatus("started");
        showToast("League started successfully!", "success");
        refreshTournamentData();
      } else {
        showToast("Failed to start tournament", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  const endTournament = async () => {
    try {
      const response = await axiosInstance.put("/tournament/status", {
        status: LEAGUES_STATUS.FINISHED,
        tournamentId: tournamentData.id,
      });

      if (response.status === 200) {
        setStatus("finished");
        showToast("League ended successfully!", "success");
        refreshTournamentData();
      } else {
        showToast("Failed to end tournament", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  const handleAddScoreSubmit = async (matchId, playerScores) => {
    const body = {
      matchId,
      tournamentId: tournamentData.id,
      players: playerScores,
    };

    try {
      const response = await axiosInstance.put("/updateScore", body);

      if (response.status === 200) {
        showToast("Scores updated successfully!", "success");
        handleCloseDrawer();
        refreshTournamentData();
      } else {
        showToast("Failed to update scores", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  const handleViewLeague = () => {
    navigate(`/leagues/${tournamentData.id}`);
  };

  const getFormattedPlayerData = () => {
    const players = {};
    tournamentData.matches.forEach((match, matchIndex) => {
      match.playerDetails.forEach((player) => {
        if (!players[player.id]) {
          players[player.id] = {
            _id: player.id,
            name: player.name,
            scores: Array(tournamentData.matches.length).fill(
              <DoNotDisturbOnTotalSilenceIcon />
            ),
            total: 0,
            replace: false,
          };
        }
        if (player.availability) {
          if (player.score === undefined || player.score === 0) {
            players[player.id].scores[matchIndex] = "0";
          } else {
            players[player.id].scores[matchIndex] = player.score;
            players[player.id].total += player.score;
          }
        } else {
          players[player.id].scores[matchIndex] = <StyledCancelIcon />;
          players[player.id].replace = true;
        }
      });
    });

    return Object.values(players).map((player) => {
      const totalMatchesPlayed = player.scores.filter(
        (score) => typeof score === "number" || score === "0"
      ).length;
      return {
        ...player,
        average: totalMatchesPlayed ? player.total / totalMatchesPlayed : 0,
      };
    });
  };

  const formattedPlayerData = getFormattedPlayerData();

  const headers = [
    "Player Name",
    ...tournamentData.matches.map((match) => match.name),
    "Total",
    "Average",
    "",
  ];

  const userPlayer = formattedPlayerData.find(
    (player) => player._id === playerId
  );

  const getPaymentStatus = (playerId) => {
    const payment = paymentData.find((payment) => payment.userId === playerId);
    return payment ? "Paid" : "Due";
  };

  const handlePaymentModalOpen = (playerId) => {
    setSelectedPlayer(playerId);
    setModalOpen(true);
  };

  const handlePaymentModalClose = () => {
    setSelectedPlayer(null);
    setModalOpen(false);
  };

  const handleUpdatePayment = async () => {
    try {
      const response = await axiosInstance.put("/updatepayment", {
        tournamentId: tournamentData.id,
        userId: selectedPlayer,
        amountPaid: amount,
        paymentMode: "cash",
        recordedBy: "66e121755228c69ee867d0f8",
        currency: currency,
        status: "paid",
      });

      if (response.status === 200) {
        showToast("Payment updated successfully!", "success");
        fetchPaymentData();
        handlePaymentModalClose();
      } else {
        showToast("Failed to update payment", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  return (
    <StyledTableMainContainer>
      <StyledHeader>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StyledImage
            src="/images/svg/tournament-icon.svg"
            alt="League icon"
          />
          <StyledTitle>{tournamentData.name}</StyledTitle>
        </Box>
        <StyledScheduleDates>
          <Typography component="span" variant="body">
            Schedule Dates:
          </Typography>
          <CalendarMonthOutlinedIcon
            fontSize="small"
            sx={{ marginLeft: "10px" }}
          />
          <Typography component="span" className="date" variant="body">
            {new Date(tournamentData.startTime).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}{" "}
            -{" "}
            {new Date(tournamentData.endTime).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </Typography>
        </StyledScheduleDates>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {location.pathname === "/" ? (
            <StyledButton
              variant="contained"
              startIcon={<TourOutlinedIcon />}
              onClick={handleViewLeague}
            >
              View League
            </StyledButton>
          ) : (
            <>
              {isAdmin && status === LEAGUES_STATUS.CREATED && (
                <StyledButton
                  variant="contained"
                  startIcon={<TourOutlinedIcon />}
                  onClick={startTournament}
                >
                  Start Tournament
                </StyledButton>
              )}
              {status === LEAGUES_STATUS.STARTED && (
                <>
                  {isAdmin || userPlayer ? (
                    <StyledButton
                      variant="contained"
                      style={{ marginRight: "10px" }}
                      startIcon={<PostAddIcon sx={{ mt: '-3px'}} />}
                      onClick={() => handleOpenDrawer("addScore")}
                    >
                      Add Score
                    </StyledButton>
                  ) : null}

                  {isAdmin ? (
                    <Tooltip title="End League">
                      <StyledButton
                        variant="contained"
                        onClick={endTournament}
                        startIcon={<CheckCircleIcon />}
                      >
                        end
                      </StyledButton>
                    </Tooltip>
                  ) : null}
                </>
              )}
              {status === LEAGUES_STATUS.FINISHED && (
                <StyledButton variant="contained">Completed</StyledButton>
              )}
              {!isAdmin && !userPlayer ? (
                <StyledButton variant="contained">Not in Match</StyledButton>
              ) : null}
            </>
          )}
        </Box>
      </StyledHeader>
      <StyledTableMainContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableHeadCell key={index}>
                  {index === 0 || index > tournamentData.matches.length ? (
                    header
                  ) : (
                    <Tooltip
                      title={new Date(
                        tournamentData.matches[index - 1].startDate
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    >
                      <span>{header}</span>
                    </Tooltip>
                  )}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedPlayerData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((player, i) => (
                <TableRow key={player._id}>
                  <StyledTableCell>
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ gap: "10px" }}
                      >
                        <CustomAvatar
                          alt={player.name}
                          src={`https://randomuser.me/api/portraits/men/${
                            i + 1
                          }.jpg`}
                        />
                        <Box>
                          <StyledTableUserName variant="body1">
                            {player.name}
                          </StyledTableUserName>
                          <Box sx={{ display: "flex" }}>
                            <Box
                              sx={{
                                alignContent: "center",
                                borderRadius: "18px",
                                backgroundColor:
                                  getPaymentStatus(player._id) === "Paid"
                                    ? "#E8EDF3"
                                    : "#E8F3E8",
                                color:
                                  getPaymentStatus(player._id) === "Paid"
                                    ? "#123013"
                                    : "#0F8B14",
                                textAlign: "center",
                                width: "44px",
                                height: "19px",
                                cursor:
                                  getPaymentStatus(player._id) === "Due"
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() =>
                                getPaymentStatus(player._id) === "Due" &&
                                handlePaymentModalOpen(player._id)
                              }
                            >
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  fontWeight: "600",
                                }}
                              >
                                {getPaymentStatus(player._id)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </StyledTableCell>
                  {player.scores.map((score, idx) => (
                    <StyledTableCell key={idx}>
                      {score === 0 || score === "0" ? "0" : score}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell>{player.total}</StyledTableCell>
                  <StyledAverageCell>
                    <Typography className="average">
                      {player.average.toFixed(2)}
                    </Typography>
                  </StyledAverageCell>
                  {status !== LEAGUES_STATUS.FINISHED ? (
                    <StyledTableCell>
                      <IconButton
                        disableRipple
                        onClick={(event) => handleClick(event, player._id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={
                          Boolean(anchorEl) && selectedPlayer === player._id
                        }
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {isAdmin && (
                          <>
                            <MenuItem onClick={handleReplace}>Replace</MenuItem>
                            <Divider />
                            <MenuItem onClick={handleDelete}>Remove</MenuItem>
                          </>
                        )}
                        {!isAdmin &&
                          userPlayer &&
                          userPlayer._id === player._id && (
                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                          )}
                        {!isAdmin && !userPlayer && player.replace && (
                          <MenuItem onClick={handleReplaceRequest}>
                            Replace Request
                          </MenuItem>
                        )}
                      </Menu>
                    </StyledTableCell>
                  ) : null}
                </TableRow>
              ))}
          </TableBody>
        </StyledTable>
        <TablePaginationComponent
          count={formattedPlayerData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </StyledTableMainContainer>
      <SwipeableDrawerComponent
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        component={drawerComponent}
        matches={tournamentData.matches}
        players={userPlayer ? [userPlayer] : formattedPlayerData}
        onSubmit={handleAddScoreSubmit}
      />
      <Modal open={modalOpen} onClose={handlePaymentModalClose}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            width: "400px",
            outline: "none",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Update Payment
          </Typography>
          <TextField
            label="Amount"
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{
              startAdornment: <Typography sx={{ pr: 1 }}>{currency}</Typography>,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Payment Mode"
            value="Cash"
            fullWidth
            InputProps={{ readOnly: true }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleUpdatePayment}
            disabled={!amount}
          >
            Update Payment
          </Button>
        </Paper>
      </Modal>
    </StyledTableMainContainer>
  );
};

export default Tournament;
