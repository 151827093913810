import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers['clientToken'] = token;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
