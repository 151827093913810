import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid } from "@mui/material";
import SideNavbar from "../../components/Navbar/SideNavbar";
import {
  StyledMainCardContainer,
  StyledMainContainer,
  StyledSpaceBetween,
} from "../../styles/StyledComponents";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import BackButton from "../../components/BackTo";
import TopPlayers from "../../components/Dashboard/TopPlayers";
import UpcomingTournaments from "../../components/Tournaments/UpcomingTournaments";
import TournamentTabs from "../../components/Tabs/TournamentTabs";
import AddPlayersTab from "../../components/Tournaments/CreateTournamentPlayer";
import TournamentDetailsTabs from "../../components/Tournaments/CreateTournamentDetails";
import ActionButtons from "../../components/Buttons/ActionButtons";
import axiosInstance from "../../utils/axios";
import { useToast } from "../../utils/ToastContext";
import ScheduleComponent from "../../components/Tournaments/ScheduleComponent.jsx";
import FacilitySelection from "../../components/Tournaments/Facilities.jsx";
import RulesComponent from "../../components/Tournaments/Rules.jsx";

export default function CreateTournaments() {
  const [activeTab, setActiveTab] = useState(0);
  const [tournamentName, setTournamentName] = useState("");
  const [matches, setMatches] = useState([
    { name: "", startDate: new Date().toISOString() },
  ]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [captain, onCaptainSelect] = useState("");
  const [facility, onFacilitySelect] = useState("");
  const [rules, onRulesChange] = useState([]);
  const [tournamentDetails, setTournamentDetails] = useState({});
  const tournamentData = {
    ...tournamentDetails,
    leagueCaptain: captain,
    facility,
    rules,
    players: selectedPlayers.map((player) => player._id),
    matches: matches.map(({ name, startDate }) => ({ name, startDate })),
  };

  const navigate = useNavigate();
  const showToast = useToast();

  const handleNext = () => {
    if (activeTab < 4) setActiveTab(activeTab + 1);
  };

  const handlePrevious = () => {
    if (activeTab > 0) setActiveTab(activeTab - 1);
  };

  const handleSubmit = async () => {
    if (matches.length < 1) {
      showToast("Please add at least one match.", "warning");
      return;
    }

    if (selectedPlayers.length < 5) {
      showToast("Please add at least five players.", "warning");
      return;
    }

    try {
      await axiosInstance.post("/tournaments", tournamentData);
      showToast("League created successfully!", "success");
      navigate("/leagues");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to create tournament.";
      showToast(`Error: ${errorMessage}`, "error");
    }
  };

  const onDataSubmit = () => {

  }

  const tabComponents = [
    <TournamentDetailsTabs
      onDataSubmit={setTournamentDetails}
    />,
    <ScheduleComponent
      tournamentName={tournamentName}
      setTournamentName={setTournamentName}
      matches={matches}
      setMatches={setMatches}
    />,
    <AddPlayersTab
      selectedPlayers={selectedPlayers}
      setSelectedPlayers={setSelectedPlayers}
      onCaptainSelect={onCaptainSelect}
    />,
    <FacilitySelection onFacilitySelect={onFacilitySelect}/>,
    <RulesComponent onRulesChange={onRulesChange}/>,
  ];

  const renderActionButtons = () => (
    <ActionButtons
      cancelLabel={activeTab === 0 ? "Cancel" : "Previous"}
      nextLabel={activeTab === 4 ? "Submit" : "Next"}
      onNext={activeTab === 4 ? handleSubmit : handleNext}
      onCancel={handlePrevious}
      cancelButtonProps={{
        sx: {
          width: "100px",
          marginRight: "10px",
          "&:hover": {
            border: "1px solid grey",
          },
        },
      }}
      nextButtonProps={{
        sx: {
          backgroundColor: "#255BE8",
          border: "none",
          color: "#fff",
          width: "100px",
        },
      }}
      containerStyle={{
        marginTop: "20px",
        paddingBottom: "10px",
      }}
      dividerStyle={{ marginBottom: "15px" }}
    />
  );

  return (
    <StyledMainContainer>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />

        <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
          <Grid item xs={12}>
            <StyledSpaceBetween>
              <WelcomeHeading label="Create League" variant="h6" />
            </StyledSpaceBetween>
            <BackButton label="Back to Dashboard" to="/" variant="body1" />
          </Grid>

          <Grid item xs={12} md={8.5}>
            <StyledMainCardContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                maxHeight: "calc(100vh - 150px)",
                overflowY: "auto",
                minHeight: "100%",
              }}
            >
              <Box style={{ display: "flex", marginRight: "20px" }}>
                {["League Details", "Select Dates", "Assign Player", "Assign Facility", "Rules"].map(
                  (label, index) => (
                    <TournamentTabs
                      key={index}
                      label={label}
                      active={activeTab === index}
                      onClick={() => setActiveTab(index)}
                    />
                  )
                )}
              </Box>
              <Divider />
              <Box sx={{ marginTop: "20px", flexGrow: 1 }}>
                {tabComponents[activeTab]}
              </Box>
              <Box sx={{ marginTop: "110px", flexGrow: 1 }}>
                {renderActionButtons()}
              </Box>
            </StyledMainCardContainer>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <TopPlayers />
              </Grid>
              <Grid item xs={12}>
                <UpcomingTournaments />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledMainContainer>
  );
}
