import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
import SideNavbar from "../../components/Navbar/SideNavbar";
import CreateTournamentButton from "../../components/Buttons/CreateTournamentButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  StyledMainContainer,
  StyledSpaceBetween,
  StyledTable,
  StyledTableCell,
  StyledTableCellText,
  StyledTableMainContainer,
} from "../../styles/StyledComponents";
import TopPlayers from "../../components/Dashboard/TopPlayers";
import TableHeader from "../../components/Table/TableHeader";
import TablePaginationComponent from "../../components/Pagination/TablePaginationComponent";
import axiosInstance from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useToast } from "../../utils/ToastContext";

const headers = ["Player Name", "Email", "Phone Number", "Actions"];

const Players = ({ role = "player" }) => { // Default role prop
  const navigate = useNavigate();
  const showToast = useToast(); // Using useToast hook to display toast notifications
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [playerData, setPlayerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleClick = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (playerId) => {
    if (window.confirm("Are you sure you want to delete this player?")) {
      try {
        await axiosInstance.delete(`/players/${playerId}`);
        showToast("Player deleted successfully", "success");
        // Remove the deleted player from the state
        setPlayerData(playerData.filter((player) => player._id !== playerId));
      } catch (error) {
        console.error("Error deleting player:", error);
        showToast("Error deleting player", "error");
      }
    }
    handleClose();
  };

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await axiosInstance.get(`/getuserbyrole?role=${role}`);
        console.log("Fetched data:", response.data);

        const data = response.data?.data || [];

        // Add avatars if needed
        const playersWithAvatars = data.map((player, index) => ({
          ...player,
          avatar: `https://randomuser.me/api/portraits/med/men/${index % 99}.jpg`, // Random user image
        }));

        setPlayerData(playersWithAvatars);
      } catch (error) {
        console.error("Error fetching player data:", error);
        setError("Error fetching player data");
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [role]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log("playerData", playerData);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <StyledMainContainer>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />

        <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
          <Grid item xs={12}>
            <StyledSpaceBetween>
              <WelcomeHeading label="Players" variant="h6" />
              <CreateTournamentButton
                label="Add Player"
                icon={<AddCircleIcon />}
                component="label"
                variant="contained"
                onClick={() => navigate("/leagues/create")}
              />
            </StyledSpaceBetween>
          </Grid>

          <Grid item xs={12} md={8.5}>
            <StyledTableMainContainer>
              <Box sx={{ padding: "15px 10px", display: "flex" }}>
                <img
                  src="/images/svg/tournament-icon.svg"
                  alt="tournament-icon"
                />
                <Typography
                  sx={{
                    margin: "5px 0px 0px 5px",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Players
                </Typography>
              </Box>
              <StyledTableMainContainer component={Paper}>
                <StyledTable>
                  <TableHeader headers={headers} />
                  <TableBody>
                    {playerData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={headers.length}>
                          <Typography align="center">
                            No players available
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      playerData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((player, index) => (
                          <TableRow key={player._id}>
                            <TableCell sx={{ padding: "10px" }}>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <img
                                  src={
                                    player.avatar || "/path/to/default/image.png"
                                  }
                                  alt={player.name}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50%",
                                    marginRight: 8,
                                  }}
                                />
                                <StyledTableCellText>
                                  {player.name || "N/A"}
                                </StyledTableCellText>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <StyledTableCellText>
                                {player.email}
                              </StyledTableCellText>
                            </TableCell>
                            <TableCell>
                              <StyledTableCellText>
                                {player.phoneNumber || "N/A"}
                              </StyledTableCellText>
                            </TableCell>
                            <StyledTableCell>
                              <IconButton
                                disableRipple
                                onClick={(event) => handleClick(event, index)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={
                                  Boolean(anchorEl) && selectedRowIndex === index
                                }
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuItem
                                  onClick={() => handleDelete(player._id)}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </StyledTableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </StyledTable>
                <TablePaginationComponent
                  count={playerData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </StyledTableMainContainer>
            </StyledTableMainContainer>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} mb={2}>
                <TopPlayers />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledMainContainer>
  );
};

export default Players;
