
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/Auth';
import { ToastProvider } from "./utils/ToastContext";
import Login from './pages/signin';
import Dashboard from './pages/dashboard';
import Awards from './pages/awards';
import CreateTournaments from './pages/createTournaments';
import Tournaments from './pages/tournaments/tabs';
import TournamentDetails from './pages/tournaments/single'
import ProtectedRoute from './components/Routes/ProtectedRoute';
import SignUp from './pages/signup';
import { LayoutWithNavbar, LayoutWithoutNavbar } from './components/Navbar/LayoutWithNavbar';
import Players from './pages/players';
import ReplaceSubPlayer from './pages/replace/Index';
import PlayerRequest from './pages/request';
import PlayerStatus from './pages/status';
import Facilities from './pages/facilities';

function App() {
  return (
  <Router>
    <ToastProvider>
      <AuthProvider>
        <Routes>
          <Route element={<LayoutWithoutNavbar />}>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<SignUp />} />
          </Route>
          <Route element={<LayoutWithNavbar />}>
            <Route path='/' element={<ProtectedRoute element={<Dashboard />} allowedRoles={['admin', 'player']} />} />
            <Route path='/players' element={<ProtectedRoute element={<Players />} allowedRoles={['admin', 'coach', 'coordinator']} />} />
            <Route path='/coaches' element={<ProtectedRoute element={<Players role="coach" />} allowedRoles={['admin', 'coach']} />} />
            <Route path='/coordinators' element={<ProtectedRoute element={<Players role="coordinator"/>} allowedRoles={['admin', 'coach', 'coordinator']} />} />
            <Route path='/leagues' element={<ProtectedRoute element={<Tournaments />} allowedRoles={['admin', 'player']} />} />
            <Route path='/facilities' element={<ProtectedRoute element={<Facilities />} allowedRoles={['admin', 'player']} />} />
            <Route path='/leagues/create' element={<ProtectedRoute element={<CreateTournaments />} allowedRoles={['admin', 'coach']} />} />
            <Route path='/leagues/:id' element={<ProtectedRoute element={<TournamentDetails />} allowedRoles={['admin', 'player', 'coach', 'coordinator']} />} />
            <Route path='/replace/:id/:tournamentId' element={<ProtectedRoute element={<ReplaceSubPlayer />} allowedRoles={['admin', 'coach']} />} />
            <Route path='/status/:id/:tournamentId' element={<ProtectedRoute element={<PlayerStatus />} allowedRoles={['admin', 'player']} />} />
            <Route path='/request/:id/:tournamentId' element={<ProtectedRoute element={<PlayerRequest />} allowedRoles={['admin', 'player']} />} />
            <Route path='/awards' element={<ProtectedRoute element={<Awards />} allowedRoles={['admin', 'player']} />} />
          </Route>
          <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
      </AuthProvider>
    </ToastProvider>
  </Router>  
  );
}

export default App;
