import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Radio,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import CustomAvatar from "../Custom/CustomAvatar";
import ActionButtons from "../Buttons/ActionButtons";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { StyledArrowBox, StyledDateBox } from "../../styles/StyledComponents";
import axiosInstance from "../../utils/axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "10px",
  padding: "8px",
}));

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#EBECF3",
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const StyledAddPlayersHeading = styled(Typography)({
  fontSize: "18px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
});

const HighlightedText = styled("span")({
  fontWeight: 700,
  color: "#255BE8",
});

const StyledTableTextListItem = styled(Typography)({
  fontSize: "12px",
});

const AddSubPlayersDrawer = ({ onClose, matchId, playerId, matchName, onSubmit }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const fetchSubPlayers = async () => {
      try {
        const response = await axiosInstance.get(`/subplayers/${matchId}/${playerId}`);
        setPlayers(response.data.players);
      } catch (error) {
        console.error("Error fetching sub-players:", error);
      }
    };

    fetchSubPlayers();
  }, [matchId, playerId]);

  const handleSelectPlayer = (playerId) => {
    setSelectedPlayer(playerId);
  };

  const handleCancel = () => {
    console.log("Cancel button clicked");
    onClose();
  };

  const handleNext = () => {
    console.log("Next button clicked");
    console.log("Selected Player ID:", selectedPlayer);
    if (onSubmit) {
      onSubmit(selectedPlayer);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 1,
        }}
      >
        <StyledAddPlayersHeading sx={{ fontSize: "14px" }}>
          Add Sub Players{" "}
          <InfoOutlined
            fontSize="small"
            sx={{ marginLeft: "5px", color: "#255BE8" }}
          />
        </StyledAddPlayersHeading>
      </Box>
      <Divider sx={{ margin: "10px 0" }} />
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}
      >
        <StyledDateBox>
          <CalendarMonthOutlinedIcon />
          <Typography sx={{ fontWeight: "500", marginLeft: "10px" }}>
            {matchName}
          </Typography>
        </StyledDateBox>
      </Box>
      <Table>
        <StyledTableHead>
          <TableRow sx={{ height: "30px" }}>
            <StyledTableCell>Player</StyledTableCell>
            <StyledTableCell>Leagues</StyledTableCell>
            <StyledTableCell>Availability</StyledTableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody sx={{ textAlign: "center" }}>
          {players.map((player, i) => (
            <TableRow key={player.id} sx={{ padding: "0px 30px" }}>
              <StyledTableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={selectedPlayer === player.id}
                    onChange={() => handleSelectPlayer(player.id)}
                    sx={{
                      width: "16px",
                      height: "16px",
                      marginRight: "5px",
                    }}
                  />
                  <CustomAvatar src={`https://randomuser.me/api/portraits/men/${i+1}.jpg`} alt={player.name} />
                  <StyledTableTextListItem
                    sx={{
                      marginLeft: "5px",
                    }}
                  >
                    {player.name}
                    <br />
                    Average:{" "}
                    <HighlightedText sx={{ color: "#000" }}>
                      {player.average ? player.average.toFixed(2) : "N/A"}
                    </HighlightedText>
                  </StyledTableTextListItem>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography sx={{ textAlign: "center", fontSize: "12px" }}>
                  {player.tournaments || "N/A"}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "10px",
                    backgroundColor:"#F9FCF9",
                    color: "#0F8B14",
                    borderRadius: "16px",
                    padding: "4px",
                    border: "0.5px solid",
                    borderColor: "#0F8B14",
                  }}
                >
                  Available
                </Typography>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ marginBottom: "10px" }}>
        <ActionButtons
          cancelLabel="Cancel"
          nextLabel="Submit"
          onCancel={handleCancel}
          onNext={handleNext}
          cancelButtonProps={{
            sx: {
              width: "100px",
              marginRight: "10px",
              "&:hover": {
                border: "1px solid grey",
              },
            },
          }}
          nextButtonProps={{
            sx: {
              backgroundColor: "#255BE8",
              border: "none",
              color: "#fff",
              width: "100px",
            },
          }}
          containerStyle={{ marginTop: "20px", paddingBottom: "10px" }}
          dividerStyle={{ marginBottom: "15px" }}
        />
      </Box>
    </Box>
  );
};

export default AddSubPlayersDrawer;
