import React from "react";
import {
  StyledCancelButton,
  StyledCreateTournamentInputButton,
} from "../../styles/StyledComponents";

const CancelButton = ({
  label,
  icon,
  variant,
  component,
  onClick,
  ...props
}) => {
  return (
    <>
      <StyledCancelButton
        variant={variant}
        component={component}
        startIcon={icon}
        onClick={onClick}
        {...props}
      >
        {label}
      </StyledCancelButton>
    </>
  );
};

export default CancelButton;
