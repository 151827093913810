import { Box } from '@mui/material'
import React from 'react'
import SideNavbar from '../../components/Navbar/SideNavbar'

const Awards = () => {
  return (
<Box sx={{display:'flex'}}>
     <SideNavbar/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
       
        <h1>Awards</h1>
    </Box>
    </Box>
  )
}

export default Awards