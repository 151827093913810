import React, { useState, useEffect } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Card, CardContent } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { yellow } from '@mui/material/colors';
import axiosInstance from "../../utils/axios";

const FacilitySelection = ({ onFacilitySelect }) => {
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await axiosInstance.get('/getfacilities');
        if (response.data && response.data.data) {
          setFacilities(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching facilities:", error);
      }
    };

    fetchFacilities();
  }, []);

  const handleSelection = (event) => {
    const selectedId = event.target.value;
    setSelectedFacility(selectedId);
    onFacilitySelect(selectedId);
  };

  const handleCardClick = (facilityId) => {
    setSelectedFacility(facilityId);
    onFacilitySelect(facilityId);
  };

  return (
    <Box sx={{ margin: '24px 34px 0 24px' }}>

      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <RadioGroup value={selectedFacility} onChange={handleSelection}>
          {facilities.map(facility => (
            <Card 
              key={facility._id} 
              variant="outlined" 
              onClick={() => handleCardClick(facility._id)}
              sx={{ 
                marginBottom: 2, 
                border: selectedFacility === facility._id ? '2px solid #1976D2' : '1px solid #E0E0E0',
                backgroundColor: selectedFacility === facility._id ? '#E3F2FD' : '#fff',
                borderRadius: 2,
                cursor: 'pointer'
              }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="h6">{facility.name}</Typography>
                    <Typography color="textSecondary">
                      {facility.streetAddress}, {facility.city}, {facility.state} {facility.postalCode}
                    </Typography>
                    <Typography color="textSecondary">Courts: {facility.courts}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" sx={{ marginRight: 1 }}>
                      Rating: {facility.rating}/5
                    </Typography>
                    <StarIcon sx={{ color: yellow[700] }} />
                  </Box>
                  <FormControlLabel 
                    value={facility._id} 
                    control={<Radio />} 
                    label="" 
                    sx={{ marginLeft: 2 }} 
                  />
                </Box>
              </CardContent>
            </Card>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default FacilitySelection;
