import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import SideNavbar from "../../components/Navbar/SideNavbar";
import {
  DateCheckbox,
  DatesContainer,
  SelectTournamentContainer,
  StyledFormControlLabel,
  StyledMainContainer,
  StyledScheduleDates,
  StyledTableMainContainer,
} from "../../styles/StyledComponents";
import TopPlayers from "../../components/Dashboard/TopPlayers";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/BackTo";
import ActionButtons from "../../components/Buttons/ActionButtons";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import axiosInstance from "../../utils/axios";
import { AuthContext } from "../../context/Auth";
import { useToast } from "../../utils/ToastContext";
import { styled } from "@mui/system";

const StyledDoNotDisturbIcon = styled(DoNotDisturbOnTotalSilenceIcon)`
  font-size: 20px;
  margin-top: 5px;
  stroke-width: 0.5px;
`;

const PlayerRequest = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { user } = useContext(AuthContext);
  const { id: playerId, tournamentId } = useParams();
  const [tournamentData, setTournamentData] = useState(null);

  const navigate = useNavigate();
  const showToast = useToast();

  useEffect(() => {
    const fetchTournamentData = async () => {
      try {
        const response = await axiosInstance.get(`/tournament/${tournamentId}`);
        const data = response.data.body;
        setTournamentData(data);

        const initiallySelectedDate = data.matches.find((match) =>
          match.playerDetails.some(
            (player) => player.id === playerId && player.availability
          )
        );

        setSelectedDate(initiallySelectedDate ? initiallySelectedDate._id : null);
      } catch (error) {
        console.error("Error fetching tournament data:", error);
      }
    };

    fetchTournamentData();
  }, [tournamentId, playerId]);

  const handleDateChange = (matchId) => {
    setSelectedDate((prevDate) => (prevDate === matchId ? null : matchId));
  };

  const handleCancel = () => {
    navigate("/");
  };

  const handleNext = async () => {
    if (!tournamentData || !selectedDate) return;

    const payload = {
      matchId: selectedDate,
      tournamentId: tournamentData.id,
      playerId,
      players: [user.id],
    };

    try {
      const response = await axiosInstance.post("/requestsubplayers", payload);
      if (response.status === 200) {
        showToast("Match request sent successfully!", "success");
        navigate("/");
      } else {
        showToast("Failed to update Match request", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  return (
    <StyledMainContainer sx={{ paddingBottom: "100px" }}>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />
        <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
          <Grid item xs={12}>
            <Box>
              <WelcomeHeading label="Add Your Details" variant="h6" />
              <BackButton label="Back to Dashboard" to="/" variant="body1" />
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <StyledTableMainContainer>
              <Box
                sx={{
                  padding: "15px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <img
                    src="/images/svg/tournament-icon.svg"
                    alt="tournament-icon"
                  />
                  <Typography
                    sx={{
                      margin: "5px 0px 0px 5px",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    {tournamentData ? tournamentData.name : "Loading..."}
                  </Typography>
                </Box>
                <StyledScheduleDates variant="body1">
                  <span className="date">Schedule Dates:</span>
                  <CalendarMonthOutlinedIcon
                    fontSize="small"
                    sx={{ marginLeft: "10px" }}
                  />
                  <span className="date">
                    {tournamentData &&
                      `${new Date(
                        tournamentData.startTime.replace("26", "06")
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })} - ${new Date(
                        tournamentData.endTime.replace("26", "06")
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}`}
                  </span>
                </StyledScheduleDates>
              </Box>

              <SelectTournamentContainer>
                <Box sx={{ marginTop: "40px" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "18px", marginBottom: "20px" }}
                  >
                    Select Dates
                  </Typography>

                  <DatesContainer>
                    {tournamentData &&
                      tournamentData.matches
                        .sort(
                          (a, b) =>
                            new Date(a.startDate) - new Date(b.startDate)
                        )
                        .map((match) => {
                          const playerInMatch = match.playerDetails.find(
                            (player) => player.id === playerId
                          );
                          const isAvailable =
                            playerInMatch && playerInMatch.availability;

                          return (
                            <Tooltip
                              key={match._id}
                              title={new Date(
                                match.startDate
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            >
                              <StyledFormControlLabel
                                control={
                                  isAvailable ? (
                                    <StyledDoNotDisturbIcon />
                                  ) : (
                                    <DateCheckbox
                                      checked={selectedDate === match._id}
                                      onChange={() =>
                                        handleDateChange(match._id)
                                      }
                                    />
                                  )
                                }
                                label={match.name}
                              />
                            </Tooltip>
                          );
                        })}
                  </DatesContainer>
                </Box>
              </SelectTournamentContainer>
              <ActionButtons
                cancelLabel="Cancel"
                nextLabel="Submit"
                onCancel={handleCancel}
                onNext={handleNext}
                cancelButtonProps={{
                  sx: {
                    width: "100px",
                    marginRight: "10px",
                    "&:hover": {
                      border: "1px solid grey",
                    },
                  },
                }}
                nextButtonProps={{
                  sx: {
                    backgroundColor: "#255BE8",
                    border: "none",
                    color: "#fff",
                    width: "100px",
                  },
                }}
                containerStyle={{ marginTop: "20px", paddingBottom: "10px" }}
                dividerStyle={{ marginBottom: "15px" }}
              />
            </StyledTableMainContainer>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} mb={2}>
                <TopPlayers />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledMainContainer>
  );
};

export default PlayerRequest;
