import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import Lottie from "lottie-react";
import sunnyAnimation from "./lottie-icons/sunny.json";
import cloudyAnimation from "./lottie-icons/cloudy.json";
import cloudAnimation from "./lottie-icons/cloud.json";
import rainAnimation from "./lottie-icons/rain.json";

const API_KEY = "2e51377f941fad0727b85b56d51aa581";
const API_URL = "https://api.openweathermap.org/data/2.5/weather";

const WeatherWidget = ({ initialCity }) => {
  const [city, setCity] = useState(initialCity);
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [invalidCity, setInvalidCity] = useState(false);

  useEffect(() => {
    if (initialCity) {
      fetchWeather(initialCity);
    }
    return () => {
      setCity("");
    };
  }, [city, initialCity]);

  const fetchWeather = async (city) => {
    setLoading(true);
    setError("");
    setWeatherData(null);

    try {
      const response = await fetch(`${API_URL}?q=${city}&appid=${API_KEY}`);
      const data = await response.json();

      if (response.ok) {
        setWeatherData({
          city: data.name,
          temperature: data.main.temp,
          description: data.weather[0].description,
        });
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("Failed to fetch weather data.");
    }

    setLoading(false);
  };

  const getWeatherIcon = (description) => {
    switch (description) {
      case "clear sky":
        return sunnyAnimation;
      case "few clouds":
      case "scattered clouds":
        return cloudyAnimation;
      case "broken clouds":
      case "overcast clouds":
        return cloudAnimation;
      case "light rain":
      case "moderate rain":
      case "heavy intensity rain":
        return rainAnimation;
      default:
        return null;
    }
  };

  const getGradientBackground = (description) => {
    switch (description) {
      case "clear sky":
        return "linear-gradient(135deg, #f6d365 0%, #fda085 100%)";
      case "few clouds":
      case "scattered clouds":
        return "linear-gradient(135deg, #89f7fe 0%, #66a6ff 100%)";
      case "broken clouds":
      case "overcast clouds":
        return "linear-gradient(135deg, #cfd9df 0%, #e2ebf0 100%)";
      case "light rain":
      case "moderate rain":
      case "heavy intensity rain":
        return "linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)";
      default:
        return "linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)";
    }
  };

  return (
    <div className="WeatherWidget" style={{ width: "100%" }}>
      <div>
        {invalidCity && <Typography color="error">Please enter a city</Typography>}
        {loading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">Please enter a valid city, {error}</Typography>
        ) : (
          weatherData && (
            <Card
              className="weather-card"
              sx={{
                width: "100%",
                height: "140px",
                position: "relative",
                overflow: "hidden",
                background: getGradientBackground(weatherData.description),
                color: "#fff",
              }}
            >
              {getWeatherIcon(weatherData.description) && (
                <Box
                    sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "150px", // Set the desired height for the Lottie animation
                    zIndex: 1,
                    opacity: 0.3,
                    overflow: "hidden", // Ensure the Lottie animation stays within bounds
                    }}
                >
                    <Lottie
                    animationData={getWeatherIcon(weatherData.description)}
                    style={{ height: "100%" }} // Apply 100% height to fill the container
                    />
                </Box>
                )}


              <CardContent
                sx={{
                  position: "relative",
                  zIndex: 2, 
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  height: "100%",
                  padding: "16px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: "#FFF",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "18px", // 75%
                      textTransform: "capitalize",
                    }}
                  >
                    {weatherData.description}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#FFF",
                      fontSize: "44px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "54px", // 122.727%
                      textTransform: "capitalize",
                    }}
                  >
                    {(weatherData.temperature - 273.15).toFixed(0)}°
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                      color: "#FFF",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "18px", // 120%
                      textTransform: "capitalize",
                    }}
                  >
                    {weatherData.city}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          )
        )}
      </div>
    </div>
  );
};

export default WeatherWidget;
