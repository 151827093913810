import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SideNavbar from "../../components/Navbar/SideNavbar";
import {
  DateCheckbox,
  DatesContainer,
  SelectTournamentContainer,
  StyledFormControlLabel,
  StyledMainContainer,
  StyledScheduleDates,
  StyledTableMainContainer,
} from "../../styles/StyledComponents";
import TopPlayers from "../../components/Dashboard/TopPlayers";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import BackButton from "../../components/BackTo";
import ActionButtons from "../../components/Buttons/ActionButtons";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import axiosInstance from "../../utils/axios";
import { useToast } from "../../utils/ToastContext";

const PlayerStatus = () => {
  const { id: playerId, tournamentId } = useParams();
  const [selectedDates, setSelectedDates] = useState([]);
  const [tournamentData, setTournamentData] = useState(null);

  const navigate = useNavigate();
  const showToast = useToast();

  useEffect(() => {
    const fetchTournamentData = async () => {
      try {
        const response = await axiosInstance.get(`/tournament/${tournamentId}`);
        const data = response.data.body;
        setTournamentData(data);

        const initialSelectedDates = data.matches
          .filter((match) =>
            match.playerDetails.some(
              (player) => player.id === playerId && player.availability // Updated to use 'player.id'
            )
          )
          .map((match) => match._id);

        setSelectedDates(initialSelectedDates);
      } catch (error) {
        console.error("Error fetching tournament data:", error);
      }
    };

    fetchTournamentData();
  }, [tournamentId, playerId]);

  const handleDateChange = (matchId) => {
    setSelectedDates((prevDates) =>
      prevDates.includes(matchId)
        ? prevDates.filter((d) => d !== matchId)
        : [...prevDates, matchId]
    );
  };

  const handleCancel = () => {
    navigate("/");
  };

  const handleNext = async () => {
    if (!tournamentData) return;

    const matches = tournamentData.matches.map((match) => ({
      matchId: match._id,
      tournamentId: tournamentData.id,
      status: selectedDates.includes(match._id),
    }));

    const payload = {
      id: playerId,
      matches,
    };

    try {
      const response = await axiosInstance.put("/updatePlayerStatus", payload);
      if (response.status === 200) {
        showToast("Player status updated successfully!", "success");
        navigate("/");
      } else {
        showToast("Failed to update player status", "error");
      }
    } catch (error) {
      showToast(`Error: ${error.message}`, "error");
    }
  };

  return (
    <StyledMainContainer sx={{ paddingBottom: "100px" }}>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />
        <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
          <Grid item xs={12}>
            <Box>
              <WelcomeHeading label="Add Your Details" variant="h6" />
              <BackButton label="Back to Dashboard" to="/" variant="body1" />
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <StyledTableMainContainer>
              <Box
                sx={{
                  padding: "15px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <img
                    src="/images/svg/tournament-icon.svg"
                    alt="tournament-icon"
                  />
                  <Typography
                    sx={{
                      margin: "5px 0px 0px 5px",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    {tournamentData ? tournamentData.name : "Loading..."}
                  </Typography>
                </Box>
                <StyledScheduleDates variant="body1">
                  <span className="date">Schedule Dates:</span>
                  <CalendarMonthOutlinedIcon
                    fontSize="small"
                    sx={{ marginLeft: "10px" }}
                  />
                  <span className="date">
                    {tournamentData &&
                      `${new Date(
                        tournamentData.startTime.replace("26", "06")
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })} - ${new Date(
                        tournamentData.endTime.replace("26", "06")
                      ).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}`}
                  </span>
                </StyledScheduleDates>
              </Box>

              <SelectTournamentContainer>
                <Box sx={{ marginTop: "40px" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "18px", marginBottom: "20px" }}
                  >
                    Select Matches
                  </Typography>

                  <DatesContainer>
                    {tournamentData &&
                      tournamentData.matches
                        .sort(
                          (a, b) =>
                            new Date(a.startDate) - new Date(b.startDate)
                        )
                        .map((match) => {
                          const playerInMatch = match.playerDetails.find(
                            (player) => player.id === playerId // Updated to use 'player.id'
                          );

                          return (
                            <Tooltip
                              key={match._id}
                              title={new Date(
                                match.startDate
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            >
                              <StyledFormControlLabel
                                control={
                                  <DateCheckbox
                                    checked={selectedDates.includes(match._id)}
                                    onChange={() => handleDateChange(match._id)}
                                    disabled={!playerInMatch} // Now should work as expected
                                  />
                                }
                                label={match.name}
                              />
                            </Tooltip>
                          );
                        })}
                  </DatesContainer>
                </Box>
              </SelectTournamentContainer>
              <ActionButtons
                cancelLabel="Cancel"
                nextLabel="Submit"
                onCancel={handleCancel}
                onNext={handleNext}
                cancelButtonProps={{
                  sx: {
                    width: "100px",
                    marginRight: "10px",
                    "&:hover": {
                      border: "1px solid grey",
                    },
                  },
                }}
                nextButtonProps={{
                  sx: {
                    backgroundColor: "#255BE8",
                    border: "none",
                    color: "#fff",
                    width: "100px",
                  },
                }}
                containerStyle={{ marginTop: "20px", paddingBottom: "10px" }}
                dividerStyle={{ marginBottom: "15px" }}
              />
            </StyledTableMainContainer>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} mb={2}>
                <TopPlayers />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledMainContainer>
  );
};

export default PlayerStatus;
