import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import NavBar from './Navbar';

export const LayoutWithNavbar = () => {
  return (
    <>
      <NavBar/>
        <Box height={30} />
      <Outlet />
    </>
  )
}

  
  export const LayoutWithoutNavbar = () => (
    <Outlet />
  );
  