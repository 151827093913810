import React from 'react'
import { StyledWelcomeTypography } from '../../styles/StyledComponents'

const WelcomeHeading = ({ label, variant, ...props }) => {
    return (
      <StyledWelcomeTypography variant={variant} {...props}>
        {label}
      </StyledWelcomeTypography>
    );
  };

export default WelcomeHeading