import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DateComponent = ({ value, onChange, placeholder }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value ? dayjs(value) : null} // Convert value to Dayjs object or null
        onChange={(newValue) => {
          if (newValue) {
            onChange(newValue.toDate()); // Convert Dayjs object to JavaScript Date
          }
        }}
        renderInput={(params) => <input {...params} placeholder={placeholder} />} // Render input
      />
    </LocalizationProvider>
  );
}

export default DateComponent;
