import React, { useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppStore } from "../../appStore";
import { AuthContext } from "../../context/Auth";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNavbar() {
  const theme = useTheme();
  const open = useAppStore((state) => state.dopen);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, isCoach, isCoordinator } = useContext(AuthContext);

  const isActive = (path) => location.pathname === path;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* Dashboard Link */}
        <ListItem
          disablePadding
          sx={{ display: "block", mb: 2 }}
          onClick={() => navigate("/")}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              backgroundColor: isActive("/") ? "#EDF1F7" : "inherit",
              borderLeft: isActive("/")
                ? `3px solid ${theme.palette.primary.main}`
                : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: isActive("/") ? theme.palette.primary.main : "inherit",
              }}
            >
              <img src="/images/svg/dashboard-icon.svg" alt="dashboard icon" />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              sx={{
                opacity: open ? 1 : 0,
                color: isActive("/") ? theme.palette.primary.main : "inherit",
              }}
            />
          </ListItemButton>
        </ListItem>

        {/* Tournaments Link */}
        <ListItem
          disablePadding
          sx={{ display: "block", mb: 2 }}
          onClick={() => navigate("/leagues")}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              backgroundColor: isActive("/leagues")
                ? "#EDF1F7"
                : "inherit",
              borderLeft: isActive("/leagues")
                ? `3px solid ${theme.palette.primary.main}`
                : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: isActive("/leagues")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
            >
              <img
                src="/images/svg/tournaments-sidenavbar-icon.svg"
                alt="Leagues sidenavbar icon"
              />
            </ListItemIcon>
            <ListItemText
              primary="Leagues"
              sx={{
                opacity: open ? 1 : 0,
                color: isActive("/leagues")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
            />
          </ListItemButton>
        </ListItem>

        {/* Facilities Link */}
        <ListItem
          disablePadding
          sx={{ display: "block", mb: 2 }}
          onClick={() => navigate("/facilities")}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              backgroundColor: isActive("/facilities")
                ? "#EDF1F7"
                : "inherit",
              borderLeft: isActive("/facilities")
                ? `3px solid ${theme.palette.primary.main}`
                : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: isActive("/facilities")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
            >
              <img
                src="/images/svg/Facilities.svg"
                alt="Facilities sidenavbar icon"
              />
            </ListItemIcon>
            <ListItemText
              primary="Facilities"
              sx={{
                opacity: open ? 1 : 0,
                color: isActive("/facilities")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
            />
          </ListItemButton>
        </ListItem>

        {/* Players Link (Admin, Coach, Coordinator) */}
        {(isAdmin || isCoach || isCoordinator) && (
          <ListItem
            disablePadding
            sx={{ display: "block", mb: 2 }}
            onClick={() => navigate("/players")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: isActive("/players")
                  ? "#EDF1F7"
                  : "inherit",
                borderLeft: isActive("/players")
                  ? `3px solid ${theme.palette.primary.main}`
                  : "inherit",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: isActive("/players")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              >
                <img src="/images/svg/players-icon.svg" alt="player icon" />
              </ListItemIcon>
              <ListItemText
                primary="Players"
                sx={{
                  opacity: open ? 1 : 0,
                  color: isActive("/players")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              />
            </ListItemButton>
          </ListItem>
        )}

        {/* Coaches Link (Admin, Coach) */}
        {(isAdmin || isCoach) && (
          <ListItem
            disablePadding
            sx={{ display: "block", mb: 2 }}
            onClick={() => navigate("/coaches")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: isActive("/coaches")
                  ? "#EDF1F7"
                  : "inherit",
                borderLeft: isActive("/coaches")
                  ? `3px solid ${theme.palette.primary.main}`
                  : "inherit",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: isActive("/coaches")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              >
                <img src="/images/svg/coach.svg" alt="coach icon" />
              </ListItemIcon>
              <ListItemText
                primary="Coaches"
                sx={{
                  opacity: open ? 1 : 0,
                  color: isActive("/coaches")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              />
            </ListItemButton>
          </ListItem>
        )}

        {/* Coordinators Link (Admin, Coordinator) */}
        {(isAdmin || isCoordinator || isCoach) && (
          <ListItem
            disablePadding
            sx={{ display: "block", mb: 2 }}
            onClick={() => navigate("/coordinators")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: isActive("/coordinators")
                  ? "#EDF1F7"
                  : "inherit",
                borderLeft: isActive("/coordinators")
                  ? `3px solid ${theme.palette.primary.main}`
                  : "inherit",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: isActive("/coordinators")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              >
                <img src="/images/svg/coordinator.svg" alt="coordinator icon" />
              </ListItemIcon>
              <ListItemText
                primary="Coordinators"
                sx={{
                  opacity: open ? 1 : 0,
                  color: isActive("/coordinators")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              />
            </ListItemButton>
          </ListItem>
        )}

        {/* Awards Link */}
        <ListItem
          disablePadding
          sx={{ display: "block", mb: 1 }}
          onClick={() => navigate("/awards")}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              backgroundColor: isActive("/awards") ? "#EDF1F7" : "inherit",
              borderLeft: isActive("/awards")
                ? `3px solid ${theme.palette.primary.main}`
                : "inherit",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: isActive("/awards")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
            >
              <img src="/images/svg/awards-icon.svg" alt="awards icon" />
            </ListItemIcon>
            <ListItemText
              primary="Awards"
              sx={{
                opacity: open ? 1 : 0,
                color: isActive("/awards")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
            />
          </ListItemButton>
        </ListItem>
      </Drawer>
    </Box>
  );
}
