import React, { useState } from 'react';
import { Box, TextField, IconButton, Typography, Grid, Alert, Button } from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from 'dayjs';

const ScheduleComponent = ({ tournamentName, setTournamentName, matches, setMatches }) => {
  const [numMatches, setNumMatches] = useState(1);
  const [scheduleEvery, setScheduleEvery] = useState(1);
  const [startDate, setStartDate] = useState(dayjs());
  const [userDefinedDates, setUserDefinedDates] = useState([{ date: dayjs(), time: dayjs() }]);
  const [error, setError] = useState('');
  const [numMatchesError, setNumMatchesError] = useState('');
  const [scheduleEveryError, setScheduleEveryError] = useState('');

  const generateMatches = () => {
    try {
      if (numMatches < 1 || scheduleEvery < 1) {
        throw new Error('Number of matches and interval must be greater than 0.');
      }

      const newMatches = [];
      for (let i = 0; i < numMatches; i++) {
        newMatches.push({
          date: dayjs(startDate).add(i * scheduleEvery, 'day'),
          time: dayjs(),
        });
      }
      setUserDefinedDates(newMatches);
      updateMatchData(newMatches);
      setError('');
    } catch (e) {
      setError(e.message);
    }
  };

  const updateMatchData = (updatedDates) => {
    const formattedMatches = updatedDates.map((match, index) => ({
      name: `Match ${index + 1}`,
      startDate: dayjs(match.date).hour(match.time.hour()).minute(match.time.minute()).second(0).toISOString(),
    }));

    setMatches(formattedMatches);
  };

  const handleNumMatchesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 || e.target.value === '') {
      setNumMatches(value);
      setNumMatchesError('');
    } else {
      setNumMatchesError('Number of matches must be greater than 0.');
    }
  };

  const handleScheduleEveryChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 || e.target.value === '') {
      setScheduleEvery(value);
      setScheduleEveryError('');
    } else {
      setScheduleEveryError('Interval must be greater than 0.');
    }
  };

  const handleDateChange = (index, newDate) => {
    if (newDate.isBefore(dayjs(), 'day')) {
      setError('Date cannot be before today.');
      return;
    }
    const updatedMatches = [...userDefinedDates];
    updatedMatches[index].date = newDate;
    setUserDefinedDates(updatedMatches);
    updateMatchData(updatedMatches);
  };

  const handleTimeChange = (index, newTime) => {
    const updatedMatches = [...userDefinedDates];
    updatedMatches[index].time = newTime;
    setUserDefinedDates(updatedMatches);
    updateMatchData(updatedMatches);
  };

  const handleDelete = (index) => {
    const updatedMatches = userDefinedDates.filter((_, i) => i !== index);
    setUserDefinedDates(updatedMatches);
    setNumMatches(updatedMatches.length);
    updateMatchData(updatedMatches);
  };

  const handleRefresh = () => {
    if (numMatches >= 1 && scheduleEvery >= 1) {
      generateMatches();
    } else {
      setError('Number of matches and interval must be greater than 0.');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ padding: 2, maxWidth: 800, margin: 'auto' }}>
        <Typography variant="h6" gutterBottom>
          Select Dates & Times
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}
        {(numMatchesError || scheduleEveryError) && (
          <Alert severity="error">
            {numMatchesError}
            {scheduleEveryError}
          </Alert>
        )}

        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 2, backgroundColor: '#f5f5f5', padding: 2, borderRadius: 2 }}>
          <TextField
            label="Number of Matches"
            type="number"
            value={numMatches}
            onChange={handleNumMatchesChange}
            inputProps={{ min: 1 }}
            error={!!numMatchesError}
            size="small"
            helperText={numMatchesError ? numMatchesError : 'Total matches'}
          />

          <TextField
            label="Schedule Every"
            type="number"
            value={scheduleEvery}
            onChange={handleScheduleEveryChange}
            inputProps={{ min: 1 }}
            error={!!scheduleEveryError}
            size="small"
            helperText={scheduleEveryError ? scheduleEveryError : 'Days Apart'}
          />

          <Button variant="contained" color="primary" onClick={handleRefresh} startIcon={<RefreshIcon />}>
            Refresh
          </Button>
        </Box>

        {userDefinedDates.map((match, index) => (
          <Grid container spacing={2} key={index} sx={{ marginBottom: 2, alignItems: 'center' }}>
            <Grid item xs={1}>
              <Typography>{index + 1}</Typography>
            </Grid>

            <Grid item xs={4}>
              <DatePicker
                label="Select Date"
                value={match.date}
                size="small"
                onChange={(newDate) => handleDateChange(index, newDate)}
                minDate={dayjs()}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            <Grid item xs={3}>
              <TimePicker
                label="Select Time"
                size="small"
                value={match.time}
                onChange={(newTime) => handleTimeChange(index, newTime)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton onClick={() => handleDelete(index)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>
    </LocalizationProvider>
  );
};

export default ScheduleComponent;
