import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import TournamentTabs from "../../components/Tabs/TournamentTabs";
import {
  StyledMainCardContainer,
  StyledMainContainer,
  StyledSpaceBetween,
} from "../../styles/StyledComponents";
import TopPlayers from "../../components/Dashboard/TopPlayers";
import WelcomeHeading from "../../components/Dashboard/WelcomeHeading";
import { useNavigate } from "react-router-dom";
import CreateTournamentButton from "../../components/Buttons/CreateTournamentButton";
import LiveRightNow from "../../components/Dashboard/LiveRightNow";
import SideNavbar from "../../components/Navbar/SideNavbar";
import TournamentsList from "../../components/Tournaments/TournamentsList";
import { LEAGUES_STATUS } from "../../utils/constants"

const Tournaments = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return <TournamentsList status={LEAGUES_STATUS.STARTED} />;
      case 1:
        return <TournamentsList status={LEAGUES_STATUS.CREATED} />;
      case 2:
        return <TournamentsList status={LEAGUES_STATUS.FINISHED} />;
      default:
        return null;
    }
  };

  return (
    <StyledMainContainer>
      <Box sx={{ display: "flex" }}>
        <SideNavbar />

        <Grid container spacing={2} sx={{ marginTop: "30px", flexGrow: 1 }}>
          <Grid item xs={12}>
            <StyledSpaceBetween>
              <WelcomeHeading label="Leagues" variant="h6" />
              <CreateTournamentButton
                label="Create League"
                icon={<AddCircleIcon />}
                component="label"
                variant="contained"
                onClick={() => navigate("/leagues/create")}
              />
            </StyledSpaceBetween>
          </Grid>
          <Grid item xs={12} md={8.5}>
            <StyledMainCardContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <Box style={{ display: "flex", marginRight: "20px" }}>
                <TournamentTabs
                  label="Current Leagues"
                  active={activeTab === 0}
                  onClick={() => setActiveTab(0)}
                />
                <TournamentTabs
                  label="Upcoming Leagues"
                  active={activeTab === 1}
                  onClick={() => setActiveTab(1)}
                />
                <TournamentTabs
                  label="Past Leagues"
                  active={activeTab === 2}
                  onClick={() => setActiveTab(2)}
                />
              </Box>
              <Divider />
              <Box sx={{ flexGrow: 1 }}>
                {renderContent()}
              </Box>
            </StyledMainCardContainer>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <TopPlayers />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledMainContainer>
  );
};

export default Tournaments;
