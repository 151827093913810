import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { StyledBackTo } from '../styles/StyledComponents';



const BackButton = ({ label, icon,variant, to,style,  ...props }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
  <Box onClick={handleClick} {...props} sx={{display:'flex'}}>
      <IconButton edge="start" color="inherit" aria-label="back" disableRipple>
    {icon || <KeyboardBackspaceIcon />}
  </IconButton>
   
     
      <StyledBackTo variant={variant}>{label}</StyledBackTo>
   
  </Box>
  );
};



export default BackButton;
